import React from "react";
import "./BuyCar.scss";
import { Box, Grid, Paper, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import AdvanceSearch from "../../Components/AdvanceSearch/AdvanceSearch";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ProductMaterialCard3 } from "../../Components/ProductMaterialCard/ProductMaterialCard";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

import "../../static/css/forms_style/forms_style.css";
import { FormattedMessage } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function BuyCar(props) {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <div id="BuyCar">
      <div className="BuyBike_header">
        <h3 className="cyan_style" style={{ width: matches ? "50%" : "70%" }}>
        <FormattedMessage id="Buy" />
        </h3>
      </div>
      <div className="BuyBike_body">
        <Section1></Section1>
        <Section2></Section2>
      </div>
    </div>
  );
}

export default BuyCar;

function Section1() {
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //     setAge(event.target.value);
  // };

  // // search filter

  // const [searchFilter, setSearchFilter] = React.useState('');
  // const handleChangeSearchFilter = (event) => {
  //     setSearchFilter(event.target.value);
  // };

  // // sorting
  // const [sorting, setSorting] = React.useState('');
  // const handleChangeSorting = (event) => {
  //     setSorting(event.target.value);
  // };

  // // price filter
  // const [priceFilter, setPriceFilter] = React.useState('');
  // const handleChangePriceFilter = (event) => {
  //     setPriceFilter(event.target.value);
  // };

  // for advance search modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="section1">
      <Box className="box_content">
        <form action="" className="form_search">
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      Brand
                    </option>
                    <option value="All">All</option>
                    <option value="Audi">Audi</option>
                    <option value="BMW">BMW</option>
                    <option value="MG">MG</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      Sorting
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      Price Filter
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sx={{ mt: 8, mb: 4 }}>
              <Item sx={{ boxShadow: 0, p: 0, background: "transparent" }}>
                <Box>
                  <Button
                    className="cyan_style"
                    sx={{ width: "50%", fontSize: 23 }}
                  >
                    
                    <FormattedMessage id="Search" />
                  </Button>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Item
                sx={{
                  boxShadow: 0,
                  p: 0,
                  background: "transparent",
                  position: "relative",
                }}
              >
                <Box>
                  <Button
                    onClick={handleOpen}
                    className="btn_advance_search title_line"
                  >
                    <FormattedMessage id="Advance" /> <ArrowForwardIosIcon></ArrowForwardIosIcon>
                  </Button>
                  <AdvanceSearch
                    open={open}
                    handleClose={handleClose}
                  ></AdvanceSearch>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </form>
      </Box>
    </section>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Section2() {
  const cardObject = [
    {
      id: 1,
      img: require("../../static/img/lance-asper-N9Pf2J656aQ-unsplash.jpg"),
      title: "MUSTANG GT",
      price: "17000.00 CHF",
      link: "/car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },
    {
      id: 2,
      img: require("../../static/img/benjamin-child-7Cdw956mZ4w-unsplash.jpg"),
      title: "BMW M3",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },
    {
      id: 3,
      img: require("../../static/img/jose-carbajal-8xyki0bqvgw-unsplash.jpg"),
      title: "VOLKSWAGEN",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },

    {
      id: 4,
      img: require("../../static/img/lance-asper-N9Pf2J656aQ-unsplash.jpg"),
      title: "MUSTANG GT",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },
    {
      id: 5,
      img: require("../../static/img/benjamin-child-7Cdw956mZ4w-unsplash.jpg"),
      title: "BMW M3",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },
    {
      id: 6,
      img: require("../../static/img/jose-carbajal-8xyki0bqvgw-unsplash.jpg"),
      title: "VOLKSWAGEN",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },

    {
      id: 7,
      img: require("../../static/img/lance-asper-N9Pf2J656aQ-unsplash.jpg"),
      title: "MUSTANG GT",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },
    {
      id: 8,
      img: require("../../static/img/benjamin-child-7Cdw956mZ4w-unsplash.jpg"),
      title: "BMW M3",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },
    {
      id: 9,
      img: require("../../static/img/jose-carbajal-8xyki0bqvgw-unsplash.jpg"),
      title: "VOLKSWAGEN",
      price: "17000.00 CHF",
      link: "car_details",
      postal_code: "5017",
      automatic: "169 KM",
      used: "9/2015",
      favorite: false,
    },
  ];
  return (
    <section id="section2">
      <Box>
        <h2
          className="title_line section_title"
          style={{ display: "inline-block" }}
        >
          
          <FormattedMessage id="Find-Your" />
        </h2>
        <Grid container display="flex" sx={{ my: 3 }}>
          <Grid
            item
            xs={12}
            md={6}
            className="form_search"
            sx={{ mb: 2 }}
            style={{ textAlign: "center" }}
          >
            <div className="form_group" style={{ display: "inline-block" }}>
              <select defaultValue="s" className="select_control_v2">
                <option value="s" disabled>
                  Sorting
                </option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              <KeyboardArrowDown className="form_icon"></KeyboardArrowDown>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className="form_search"
            sx={{ mb: 2 }}
            style={{ textAlign: "center" }}
          >
            <div className="form_group" style={{ display: "inline-block" }}>
              <select defaultValue="s" className="select_control_v2">
                <option value="s" disabled>
                  Vehicle Type
                </option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </select>
              <KeyboardArrowDown className="form_icon"></KeyboardArrowDown>
            </div>
          </Grid>
        </Grid>
        <Box>
          <ProductMaterialCard3
            xs={6}
            md={4}
            cardObject={cardObject}
          ></ProductMaterialCard3>
        </Box>
      </Box>
    </section>
  );
}
