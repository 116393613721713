import React, { useState } from "react";
import "./ResetPassword.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { resetPasswordHttpRequest } from "../../static/http-content";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";

const ToastParams = {
  isLoading: false,
  closeOnClick: true,
  autoClose: 5000,
  pauseOnHover: true,
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function ResetPassword(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    new_password: null,
    current_password: null,
  });

  const submitPassword = () => {
    let toast_id = toast.loading('Please wait...');
    if (password.new_password === password.confirm_new_password) {
      let formData = new FormData();
      formData.append("uidb64",new URLSearchParams(location.search).get('uidb64'));
      formData.append("token",new URLSearchParams(location.search).get('token'));
      formData.append("password", password.new_password);
      resetPasswordHttpRequest
        .post("/", formData, {headers: { Authorization: `jwt ${localStorage.getItem("refresh_token")}`},})
        .then((res) => {
          if(res?.status === 200){
            toast.update(
              toast_id,
              {
                render : res?.data.message,
                type : 'success',
                ...ToastParams
              }
            )
            navigate("/login")
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      toast.update(
        toast_id,
        {
          render : 'Confirm password is wrong',
          type : 'error',
          ...ToastParams
        }
      )
    }
  };

  return (
    <div id="ResetPassword">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan"><FormattedMessage id="Reset-Password"/></h3>
        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <FormBox2 password={password} setPassword={setPassword} />
              <FormSubmitPassword submitPassword={submitPassword} />
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}
export default ResetPassword;

const FormBox2 = ({ password, setPassword }) => {
  const matches = useMediaQuery("(min-width:640px)");
  const intl = useIntl();
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line"><FormattedMessage id="Reset-Password"/></h2>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="New-Password"/>:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "New-Password" })}
                  value={password.new_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      new_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Confirm-Password"/>:
                </label>
                <input
                  type="password"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Confirm-Password" })}
                  value={password.confirm_new_password}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      confirm_new_password: e.target.value,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSubmitPassword = (props) => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button
        sx={{ px: 6 }}
        className="btn_cyan_style"
        onClick={props.submitPassword}
      >
      <FormattedMessage id="RESET-PASSWORD"/>
      </Button>
    </Box>
  );
};


