import React, { useState } from "react";
import { Box, Button, Collapse, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "../AdvertisementHistory/AdvertisementHistory.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import DownloadIcon from "@mui/icons-material/Download";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FormattedMessage } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function MerchantHistoryDetails(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div
      id="AdvertisementHistory"
      style={{
        marginLeft: matches ? "260px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
       
          <FormattedMessage id="Merchant-Details"/>
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{ pb: 8, background: "var(--auto-text-f1)" }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 6 : 1, maxWidth: "100%" }}>
          <Box
            id="historyBox"
            sx={{
              boxShadow: 3,
              p: 6,
              borderRadius: 3,
              background: "var(--auto-text-white)",
            }}
          >
            <BoxHead></BoxHead>
            <BoxBody></BoxBody>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default MerchantHistoryDetails;

const BoxHead = () => {
  const btn_action_object = [
    {
      id: 1,
      name: <FormattedMessage id="CSV"/>,
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 2,
      name: <FormattedMessage id="Excel"/>,
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 3,
      name: <FormattedMessage id="Advertisement-Values"/>,
      icon: <DownloadIcon></DownloadIcon>,
      link: "",
    },
    {
      id: 4,
      name: <FormattedMessage id="Import-CSV"/>,
      icon: <CloudDownloadIcon></CloudDownloadIcon>,
      link: "",
    },
    {
      id: 5,
      name: <FormattedMessage id="Import-Excel"/>,
      icon: <CloudDownloadIcon></CloudDownloadIcon>,
      link: "",
    },
    {
      id: 6,
      name: <FormattedMessage id="Download-History"/>,
      icon: "",
      link: "",
    },
    {
      id: 7,
      name: <FormattedMessage id="Add-Merchant"/>,
      icon: "",
      link: "",
    },
  ];
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Box id="BoxHead">
      <form action="" className="responsiveBoxHead form_history_search">
        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label"><FormattedMessage id="From-Date"/>:</div>
          <HistoryDate></HistoryDate>
        </Box>

        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label"><FormattedMessage id="To-Date"/>:</div>
          <HistoryDate></HistoryDate>
        </Box>
        <Box className="form_group" sx={{ justifyContent: "flex-end" }}>
          <Button
            className="btn_cyan_style"
            sx={{ py: 0.5, px: 3, width: matches ? "auto" : "100%" }}
          >
           <FormattedMessage id="SUBMIT"/>
          </Button>
        </Box>
      </form>
      <Box className="btn_download_wrapper" sx={{ my: 3 }}>
        {btn_action_object.map((v) => (
          <Button
            key={v.id}
            className="btn_cyan_style"
            sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
          >
            {v.icon}
            {v.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const HistoryDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      className="datePicker"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};

function createData(
  id,
  name,
  brands,
  place_name,
  country,
  postal_code,
  action
) {
  return { id, name, brands, place_name, country, postal_code, action };
}

const rows = [
  createData(1, "John", "BMW M3", "Munich", "Germany", "1000", ""),
  createData(2, "John", "BMW M3", "Munich", "Germany", "1000", ""),
  createData(3, "John", "BMW M3", "Munich", "Germany", "1000", ""),
  createData(3, "John", "BMW M3", "Munich", "Germany", "1000", ""),
];

const BoxBody = () => {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      {matches ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, mb: rows.length == 0 && 30 }}
            aria-label="history_table"
            className="history_table"
          >
            <TableHead>
              <TableRow className="one_line_text">
                <TableCell style={{ fontStyle: "italic" }}><FormattedMessage id="#"/></TableCell>
                <TableCell align="center"><FormattedMessage id="NAME"/></TableCell>
                <TableCell align="center"><FormattedMessage id="BRANDS"/></TableCell>
                <TableCell align="center"><FormattedMessage id="PLACE-NAME"/></TableCell>
                <TableCell align="center"><FormattedMessage id="COUNTRY"/></TableCell>
                <TableCell align="center"><FormattedMessage id="POSTAL-CODE"/></TableCell>
                <TableCell align="center"><FormattedMessage id="ACTION"/></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="td" scope="row">
                    {row.id}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.brands}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.place_name}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.country}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    component="td"
                  >
                    {row.postal_code}
                  </TableCell>
                  <TableCell align="center" component="td">
                    <IconButton
                      className="btn_cyan_style"
                      aria-label="delete"
                      sx={{ p: 1 }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <List
          sx={{ width: "100%", padding: "0px" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {rows.map((row) => (
            <div
              style={{
                borderBottom: "3px solid #00aeef",
                paddingBottom: "1rem",
              }}
            >
              <ListItem>
                <ListItemIcon style={{ fontWeight: "bold" }}>
                  # {row.id}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {row.name}
                      <IconButton
                        className="btn_cyan_style"
                        aria-label="delete"
                        sx={{ p: 1, borderRadius: "10px !important" }}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                />
              </ListItem>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Brand
                  </span>
                  <span>{row.brands}</span>
                </Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Place name
                  </span>
                  <span>{row.place_name}</span>
                </Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Country
                  </span>
                  <span>{row.country}</span>
                </Box>
                <Box
                  sx={{
                    width: "inherit",
                    border: "1px solid #aaa",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    marginBlock: "0.15rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold", minWidth: "100px" }}>
                    Postal code
                  </span>
                  <span>{row.postal_code}</span>
                </Box>
              </Collapse>
            </div>
          ))}
        </List>
      )}
    </>
  );
};
