import React, { useState, useEffect } from 'react';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import {useSelector} from  'react-redux';

const loadLocaleData = (locale) => {
    
    switch (locale) {
        case 'GR':
            return import('./gr.json');
        case "FR":
            return import('./fr.json');
        case "IT":
            return import('./it.json');
        default:
            return import('./en.json');
    }
};

const Locale = ({children})=> {
 const [messages, setMessages] = useState();
 const language = useSelector((state)=>state.rootReducer.LanguageReducer.language);
 
    useEffect(() => {
      
   
        loadLocaleData(language).then(
            (d) => {
              
                setMessages(d.default);
            }
        );
    }, [language]);  
    return (
        <>
            {messages && (
                <IntlProvider locale="gr" defaultLocale="gr" messages={messages} menuItem>
                    {children}
                </IntlProvider>
            )}
        </>
    );
}

export default Locale