import React from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import LoginCardBlock from "../LoginCardBlock";
import { toast } from "react-toastify";
import FacebookIcon from "@mui/icons-material/Facebook";

const LoginWithFacebook = ({ onVerify, is_agreed_terms_condition }) => {
  const facebookHandler = (response) => {
    console.log(response, "loginData");
    if (response?.id !== undefined) {
      onVerify({ ...response, social_login: true });
    }
  };

  return (
    <>
      {/*  FacebookLogin implemented just commented  due to absence of appId */}
      <div className="relative">
        <LoginCardBlock>
          <span
            className="absolute opacity-0 inset-0 p-0"
            onClick={() => {
              if (!is_agreed_terms_condition) {
                toast.error("Please agree terms & condition");
              }
            }}
          >
            <FacebookLogin
              appId="1280667999118008"
              fields="name,email,picture,first_name,last_name"
              autoLoad={false}
              children={
                <>
                  <FacebookIcon />
                  Sign in with Facebook
                </>
              }
              className="fb-login-btn"
              onSuccess={(response) => {
                // console.log(response);
              }}
              onFail={(error) => {
                console.log("Login Failed!", error);
              }}
              onProfileSuccess={(response) => {
                facebookHandler(response);
              }}
            />
          </span>
        </LoginCardBlock>
      </div>
    </>
  );
};

LoginWithFacebook.defaultProps = {
  onVerify: () => {},
};

export default LoginWithFacebook;
