import React from "react";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import "./UserPanelAside.scss";
import Logo from "../../static/img/logo.png";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { FaTimes, FaBars } from "react-icons/fa";
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  logo: {
    height: "80px",
  },
});

function UserPanelAside(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  // firstAccordionHandle
  const [firstAccordion, setFirstAccordion] = React.useState(true);
  // Advertisement
  const firstAccordionHandle = () => {
    setFirstAccordion(!firstAccordion);
  };

  // secondAccordionHandle
  const [secondAccordion, setSecondAccordion] = React.useState(true);
  // Advertisement
  const secondAccordionHandle = () => {
    setSecondAccordion(!secondAccordion);
  };

  // thirdAccordionHandle
  const [thirdAccordion, setThirdAccordion] = React.useState(true);
  // Advertisement
  const thirdAccordionHandle = () => {
    setThirdAccordion(!thirdAccordion);
  };

  // fourthAccordionHandle
  const [fourthAccordion, setFourthAccordion] = React.useState(true);
  // Advertisement
  const fourthAccordionHandle = () => {
    setFourthAccordion(!fourthAccordion);
  };

  // fifthAccordionHandle
  const [fifthAccordion, setFifthAccordion] = React.useState(true);
  // Advertisement
  const fifthAccordionHandle = () => {
    setFifthAccordion(!fifthAccordion);
  };

  // sixthAccordionHandle
  const [sixthAccordion, setSixthAccordion] = React.useState(true);
  // Advertisement
  const sixthAccordionHandle = () => {
    setSixthAccordion(!sixthAccordion);
  };

  const aside_object = [
    {
      id: 1,
      expanded: firstAccordion,
      clickHandler: firstAccordionHandle,
      title:  <FormattedMessage id="Advertisement" />,
      
      accordion_list: [
        {
          id: 1,
          name: <FormattedMessage id="Car-Advertisement" />,
          link: "/car_advertisement",
        },
        {
          id: 2,
          name: <FormattedMessage id="Bike-Advertisement" />,
          link: "/bike_advertisement",
        },
        {
          id: 3,
          name: <FormattedMessage id="Local-Advertisement" />,
          link: "/advertisement",
        },
        {
          id: 4,
          name: <FormattedMessage id="VIP-Advertisement" />,
          link: "/vipAdvertisement",
        },
      ],
    },

    {
      id: 2,
      expanded: secondAccordion,
      clickHandler: secondAccordionHandle,
      title: <FormattedMessage id="Components" />,
      accordion_list: [
        {
          id: 1,
          name: <FormattedMessage id="Car-Components1" />,
          link: "/car_component",
        },
        {
          id: 2,
          name: <FormattedMessage id="Bike-Components1" />,
          link: "/bike_component",
        },
        {
          id: 3,
          name: <FormattedMessage id="Components-Enquiry" />,
          link: "/component_enquiry",
        },
        {
          id: 4,
          name: <FormattedMessage id="Booking-History" />,
          link: "/component_booking_history",
        },
      ],
    },

    {
      id: 3,
      expanded: thirdAccordion,
      clickHandler: thirdAccordionHandle,
      title: <FormattedMessage id="Merchant" />,
      accordion_list: [
        {
          id: 1,
          name: <FormattedMessage id="Merchant-Entry" />,
          link: "/add_merchant",
        },
        {
          id: 2,
          name: <FormattedMessage id="Merchant-Enquiry" />,
          link: "/merchant_history_details",
        },
      ],
    },

    {
      id: 4,
      expanded: fourthAccordion,
      clickHandler: fourthAccordionHandle,
      title: <FormattedMessage id="Garage" />,
      accordion_list: [
        {
          id: 1,
          name: <FormattedMessage id="Garage-Entry" />,
          link: "/garage_details",
        },
        {
          id: 2,
          name: <FormattedMessage id="Garage-Enquiry" />,
          link: "/garage_enquiry",
        },
        {
          id: 3,
          name: <FormattedMessage id="Booking-History" />,
          link: "/garage_booking_history",
        },
      ],
    },

    {
      id: 5,
      expanded: fifthAccordion,
      clickHandler: fifthAccordionHandle,
      title: <FormattedMessage id="Buy-Sell" />,
      accordion_list: [
        {
          id: 1,
          name: <FormattedMessage id="Sell-Car" />,
          link: "/sell_car1",
        },
        {
          id: 2,
          name: <FormattedMessage id="Buy-History" />,
          link: "/car_buy_history",
        },
        {
          id: 3,
          name: <FormattedMessage id="Sell-History" />,
          link: "/car_sell_history",
        },
        {
          id: 4,
          name: <FormattedMessage id="Booking-History" />,
          link: "/car_booking_history",
        },
      ],
    },

    {
      id: 6,
      expanded: sixthAccordion,
      clickHandler: sixthAccordionHandle,
      title:  <FormattedMessage id="Buy-Sell-Bike" />,
      accordion_list: [
        {
          id: 1,
          name: <FormattedMessage id="Buy-History" />,
          link: "/bike_buy_history",
        },
        {
          id: 2,
          name: <FormattedMessage id="Sell-History" />,
          link: "/bike_sell_history",
        },
        {
          id: 3,
          name: <FormattedMessage id="Booking-History" />,
          link: "/bike_booking_history",
        },
      ],
    },
  ];

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setShowSideBar(open);
  };

  const list = (
    <Box
      sx={{ width: 300, color: "#fff" }}
      role="presentation"
      // onKeyDown={toggleDrawer(false)}
      className="content_wrapper"
    >
      <Box sx={{ px: 2 }}>
        <div
          className="logo_wrapper"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link to="/" style={{ marginLeft: "1rem" }}>
            <img className={classes.logo} src={Logo} alt="logo" />
          </Link>
        </div>
      </Box>

      {aside_object.map((v) => {
        return (
          <Accordion
            key={v.id}
            expanded={v.expanded}
            sx={{
              px: 0,
              pt: 0,
              pb: 2,
              background: "transparent",
              boxShadow: 0,
            }}
          >
            <Box sx={{ px: 2 }}>
              <AccordionSummary
                sx={{ color: "white", mt: 0, mb: 0, px: 0 }}
                className="title_line"
                onClick={v.clickHandler}
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  sx={{ fontSize: "20px", color: "var(--auto-text-white)" }}
                >
                  {v.title}
                </Typography>
              </AccordionSummary>
            </Box>
            <AccordionDetails sx={{ px: 0 }}>
              <Typography component="div">
                <ul className="accordion_list">
                  {v.accordion_list.map((j) => {
                    return (
                      <li key={j.id}>
                        <Link to={j.link}>{j.name}</Link>
                      </li>
                    );
                  })}
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );

  const sideDrawer = (
    <Drawer
      open={props.showSideBar}
      onClose={toggleDrawer(false)}
      variant={matches ? "permanent" : "temporary"}
      PaperProps={{
        style: {
          backgroundColor: "var(--auto-text-47)",
          zIndex: 2,
        },
      }}
      id="UserPanelAside"
    >
      {list}
    </Drawer>
  );

  return <aside>{sideDrawer}</aside>;
}

export default UserPanelAside;
