import React from "react";
import { Box } from "@mui/material";
import "../../static/css/style_helper/style_helper.scss";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import Paper from "@mui/material/Paper";
function Imprint(props) {
  return (
    <div id="Imprint">
      <Section1></Section1>
    </div>
  );
}

export default Imprint;

function Section1() {
  const intl = useIntl();
  return (
    <section
      id="section1"
      className="bg_img_section"
      style={{
        "--bg-section-img": `url(${require("../../static/img/35cf026bb0dded565441fed7aee42ae0.jpg")})`,
      }}
    >
      <Box sx={{ py: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">
          {intl.formatMessage({ id: "Imprint" })}
        </h3>
        <div className="section_wrapper section_space_wrapper">
          {/*  <Box sx={{ pt: 5 }}>
            <h3 className="title_line section_title_lg">
              {intl.formatMessage({ id: "Imprint" })}
            </h3>
            <p className="paragraph_space1">
              {intl.formatMessage({ id: "Lorem" })}
              <br />
              <br />
              {intl.formatMessage({ id: "praesentium" })}
            </p>
          </Box> */}
        </div>
      </Box>
      <Box>
        <Paper>
          <div
            style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "10px",
              maxWidth: "600px",
              margin: "0 auto 5px auto",
            }}
          >
            <p>
              Autoby24 is the online marketplace for cars and motorcycles in
              Switzerland (
              <a
                href="https://www.autoby24.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.autoby24.ch
              </a>
              ) and Europe (
              <a
                href="https://www.autoby24.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.autoby24.com
              </a>
              ). Autoby24 has a second product called 'Goby24' (
              <a
                href="https://www.goby24.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.goby24.ch
              </a>{" "}
              /
              <a
                href="https://www.goby24.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.goby24.com
              </a>
              ), which is the online marketplace for worldwide Car Drive Share
              and passenger headquarters. Both platforms offer a comprehensive
              News and Guide area, serving as search and information portals for
              topics related to cars, motorcycles, garages, accessories, and
              carpooling centers. Autoby24 provides apps for Android and iOS,
              and Goby24 offers the same.
            </p>
            <p>
              Local and international advertisements can be posted by companies
              or users on Goby24 and Autoby24. Tourist packages are also
              available through Goby24, Autoby24, and other companies.
            </p>
            <br />
            <p>
              <span>Autoby24 GmbH</span> <br />
              <span>Hagenholzstrasse 81a</span> <br />
              <span>8050 Zürich</span> <br />
              <span>Switzerland</span>
            </p>
            <br />
            <p>
              E-Mail: <a href="mailto:info@autoby24.ch">info@autoby24.ch</a>
            </p>
            <p>
              Web:{" "}
              <a
                href="https://www.autoby24.ch"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.autoby24.ch
              </a>
            </p>
            <br />
            <span
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                color: "rgb(0, 76, 155)",
              }}
            >
              Authorized persons:
            </span>
            <br />
            <br />
            <p style={{ fontWeight: "bold" }}>
              Mujibur Rahman (Shareholder and Managing Director)
            </p>
            <p style={{ fontWeight: "bold" }}>
              Nurzaman Sarkar (Partner and Chairman of the Management)
            </p>
            <br />
            <p style={{ fontSize: "16px" }}>
              <span>Company Name: Autoby24 GmbH</span>
              <br />
              <span>Registration Number: CHE-351.419.658</span>
              <br />
              <span>Sales Tax Identification Number: CHE-351.419.658 MWST</span>
            </p>
            <br />
            <br />
            <p>
              For instructions, error messages, and general questions about the
              platform, please use our e-mail address:
              <a href="mailto:redaktion@autoby24.ch">
                redaktion@autoby24.ch
              </a>{" "}
              or <a href="mailto:redaktion@goby24.ch">redaktion@goby24.ch</a>
            </p>
            <br />
          </div>
        </Paper>
      </Box>
    </section>
  );
}
