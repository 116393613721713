import React, { useState } from "react";
import "./SliderThumb.scss";
import { Box } from "@mui/system";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

function SliderThumb({ sliderName, sliderObject }) {
  const [thumbBigImg, setThumbBigImg] = useState(sliderObject.big_image);

  const handleImageItem = (event) => {
    //console.log(event.target.getAttribute("data-thumb-big-img"));
    setThumbBigImg(event.target.getAttribute("data-thumb-big-img"));
  };
  return (
    <div id="SliderThumb">
      <div className="thumb_big_img">
        <img src={thumbBigImg} alt="img" />
      </div>
      <Box
        sx={{ px: 4, mt: 3 }}
        position="relative"
        className="slider_swiper_box_thumb"
      >
        <Swiper
          navigation={true}
          navigation={{
            //
            nextEl: `.custom_swiper_navigate_next_ddd`, //Next
            prevEl: `.custom_swiper_navigate_prev_ddd`, //Prev
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {sliderObject.thumb_img_list.map((v, i) => {
            return (
              <SwiperSlide
                key={v.id}
                className="SwiperItem"
                onClick={handleImageItem}
              >
                <img data-thumb-big-img={v.big} src={v.small} alt="img" />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          className={`swiper-button-prev custom_swiper_navigate_prev_ddd`}
        ></div>
        <div
          className={`swiper-button-next custom_swiper_navigate_next_ddd`}
        ></div>
      </Box>
    </div>
  );
}

export default SliderThumb;
