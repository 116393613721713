import React from "react";
import "./PictureUploader.scss";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
function PictureUploader({ handleUploader, registration_image }) {
  console.log(registration_image);
  return (
    <label htmlFor="Vehicle_rc_input" id="PictureUploader">
      {registration_image ? (
        <img
          src={registration_image}
          alt="registration_image"
          style={{
            width: "220px",
            height: "200px",
            borderRadius: "20px",
            objectFit: "cover",
          }}
        />
      ) : (
        <>
          <CameraAltIcon fontSize="large"></CameraAltIcon>+ APP PICTURE
        </>
      )}
    </label>
  );
}

export default PictureUploader;
