import LanguageReducer from './languageReducer'
import LoginReducer, { authReduce } from './LoginReducer'
import RegisterReducer from './RegisterReducer'
 import { combineReducers } from 'redux'

const rootReducer = combineReducers({
    LanguageReducer: LanguageReducer,
    AuthReduce:authReduce,
    RegisterReducer:RegisterReducer

})

export default rootReducer
