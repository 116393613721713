import React, { useRef } from "react";
import "./Slider.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Box } from "@material-ui/core";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

function Slider({sliderName, sliderObject }) {
  //console.log(sliderObject);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div id="sliderComponent">
      <div className="slider_wrapper">
        <h3 id="slider_name" style={{marginTop: '0px'}}>{sliderObject.slider_name}</h3>

        <Box className="slider_swiper_box" sx={{marginBottom: 3}}>
          <Swiper 
            navigation={true}
            className="car_brands_swiper "
            navigation={{
              // 
              nextEl: `.custom_swiper_navigate_next_${sliderName}`, //Next
              prevEl: `.custom_swiper_navigate_prev_${sliderName}`, //Prev
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {sliderObject.slider_items.map((v, i) => {
              return (
                <SwiperSlide key={v.id}>
                  <Card sx={{ maxWidth: "100%", boxShadow: '0px -2px 1px var(--auto-text-black-25)', borderRadius: '20px 20px 0px 0px' }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        height="220"
                        image={v.img}
                        alt="img"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          align="center"
                          component="div"
                        >
                          {v.title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions sx={{m: 0, p:0}}>
                      <Button
                        size="large"
                        sx={{ width: "100%", borderRadius: 5, boxShadow: 2 }}
                        className="btn_view_more"
                      >
                        View more{" "}
                        <ArrowForwardIcon
                          sx={{ marginLeft: 1 }}
                        ></ArrowForwardIcon>
                      </Button>
                    </CardActions>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className={`swiper-button-prev custom_swiper_navigate_prev_${sliderName}`}></div>
          <div className={`swiper-button-next custom_swiper_navigate_next_${sliderName}`}></div>
        </Box>
      </div>
    </div>
  );
}

export default Slider;
