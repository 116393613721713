import React, { useEffect, useState } from "react";
import Logo from "../../static/img/logo.png";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import "./Header.scss";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IsAuthAllow } from "../../auth_management/Auth";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Box, ListItemIcon, useMediaQuery } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FaTimes, FaBars } from "react-icons/fa";
import { connect } from "react-redux";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Locale from "../../utils/Locale";
import { getLangaugeType } from "../../Redux/Action/action";
const useStyles = makeStyles({
  header: {
    height: "var(--header-height)",
  },
  logo: {
    height: "80px",
  },
  list_box: {
    btn_navbar: {
      background: "transparent",
    },
  },
  MenuItem: {
    "&:hover": {
      backgroundColor: "var(--auto-text-danger)!important",
      color: "var(--auto-text-white)",
    },
  },
  search_input: {
    width: "230px",
    padding: "7px 15px",
    fontSize: "17px",
    border: "none",
    outline: "none",
    textAlign: "center",
    "&::placeholder": {
      color: "#D1D1D1",
    },
  },
});

function Header(props) {
  // class
  const classes = useStyles();
  const nav_children_obj = {
    sell: [
      {
        id: 1,
        name: "Sell Car",
        link: (IsAuthAllow() ? "/sell_car1" : "/login"),
      },
      {
        id: 2,
        name: "Sell Bike",
        link: (IsAuthAllow() ? "/sell_bike1" : "/login"),
      },
      {
        id: 3,
        name: "Sell Car Components",
        link: "/car_component",
      },
      {
        id: 4,
        name: "Sell Bike Components",
        link: "/bike_component",
      },
    ],
    buy: [
      {
        id: 1,
        name: "Buy Car",
        link: "/buy_car",
      },
      {
        id: 2,
        name: "Buy Bike",
        link: "/buy_bikes",
      },
      {
        id: 1,
        name: "Buy Car Components",
        link: "/buy_car_components",
      },
      {
        id: 1,
        name: "Buy Bike Components",
        link: "/buy_bike_components",
      },
    ],
    look_for_dealer: [
      {
        id: 1,
        name: "Merchant Search",
        link: "dealer",
      },
    ],
    look_for_garage: [
      {
        id: 1,
        link: "rent_garage",
        name: "Rent Garage",
      },
      {
        id: 2,
        link: "sale_garage",
        name: "Sale Garage",
      },
    ],
    dashboard: [
      {
        id: 1,
        link: "/user_panel",
        icon: <PersonIcon sx={{ marginRight: 1 }}></PersonIcon>,
        name: "User Panel",
      },
      {
        id: 2,
        link: "/change_password",
        icon: <PersonIcon sx={{ marginRight: 1 }}></PersonIcon>,
        name: "Change-Password",
      },
      
      {
        id: 3,
        link: "",
        icon: <PersonIcon sx={{ marginRight: 1 }}></PersonIcon>,
        name: "Delete Account",
      },
      {
        id: 4,
        link: "",
        icon: <LoginIcon sx={{ marginRight: 1 }}></LoginIcon>,
        name: "Logout",
      },
    ],
    language: ["EN", "GR", "FR", "IT"],
  };
  const pathname = useLocation().pathname;
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);

  const selectedLanguate = localStorage.getItem("lang") ?? 'EN';

  const[lang,setLang]=useState(selectedLanguate);
  const [menuOpen, setMenuOpen] = React.useState({
    buy: false,
    sell: false,
    dealer: false,
    garage: false,
    search: false,
    language: false,
    dashboard: false,
  });
  const matches = useMediaQuery("(min-width:960px)");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuDrawerOpen(open);
  };

  useEffect(()=>{

    if(lang  !=''){
      props.setdata(lang);
    }
  },[lang])

  const handleLanguageClick = (language) => {
  localStorage.setItem("lang",language);
  props.setdata(language);
  setLang(language);
  };

  const removeLocalStorage = () => {
    localStorage.clear("token");
    localStorage.clear("refresh_token");
    localStorage.clear("user");
    localStorage.clear("userId");
    localStorage.clear("lang");
  };

  useEffect(() => {
    console.log(IsAuthAllow());
  }, [IsAuthAllow()]);

  const list = (
    <Box
      sx={{ width: 300, color: "#fff" }}
      role="presentation"
      // onKeyDown={toggleDrawer(false)}
    >
      <List
        sx={{ width: "100%", maxWidth: 360, pb: "70px" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <div
          className="logo_wrapper"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Link
            to="/"
            style={{ marginLeft: "1rem" }}
            onClick={toggleDrawer(false)}
          >
            <img className={classes.logo} src={Logo} alt="logo" />
          </Link>
          <div onClick={toggleDrawer(false)} style={{ marginRight: "1.3rem" }}>
            <FaTimes style={{ height: "30px" }} />
          </div>
        </div>
        <ListItemButton
          className={menuOpen.buy ? "menu_item" : ""}
          onClick={() => setMenuOpen({ ...menuOpen, buy: !menuOpen.buy })}
        >
          <ListItemText primary="BUY" />
          {menuOpen.buy ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={menuOpen.buy} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="subMenu_list">
            {nav_children_obj.buy.map((item) => (
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={item.link}
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>    
            ))}
          </List>
        </Collapse>
        <ListItemButton
          className={menuOpen.sell ? "menu_item" : ""}
          onClick={() => setMenuOpen({ ...menuOpen, sell: !menuOpen.sell })}
        >
          <ListItemText primary="SELL" />
          {menuOpen.sell ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={menuOpen.sell} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="subMenu_list">
            {nav_children_obj.sell.map((item) => (
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={item.link}
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <ListItemButton
          className={menuOpen.dealer ? "menu_item" : ""}
          onClick={() => setMenuOpen({ ...menuOpen, dealer: !menuOpen.dealer })}
        >
          <ListItemText primary="LOOK FOR DEALER" />
          {menuOpen.dealer ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={menuOpen.dealer} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="subMenu_list">
            {nav_children_obj.look_for_dealer.map((item) => (
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={item.link}
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <ListItemButton
          className={menuOpen.garage ? "menu_item" : ""}
          onClick={() => setMenuOpen({ ...menuOpen, garage: !menuOpen.garage })}
        >
          <ListItemText primary="LOOK FOR GARAGE" />
          {menuOpen.garage ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={menuOpen.garage} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="subMenu_list">
            {nav_children_obj.look_for_garage.map((item) => (
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to={item.link}
                onClick={toggleDrawer(false)}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        <ListItemButton
          component={Link}
          to={"contact_us"}
          onClick={toggleDrawer(false)}
        >
          <ListItemText primary="CONTACT" />
        </ListItemButton>
        {IsAuthAllow() ? (
          <>
            <ListItemButton
              className={menuOpen.dashboard ? "menu_item" : ""}
              onClick={() =>
                setMenuOpen({ ...menuOpen, dashboard: !menuOpen.dashboard })
              }
            >
              <ListItemText primary="Dashboard" />
              {menuOpen.dashboard ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={menuOpen.dashboard} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="subMenu_list">
                {nav_children_obj.dashboard.map((item) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={item.link}
                    onClick={
                      item.name === "Logout"
                        ? () => removeLocalStorage()
                        : toggleDrawer(false)
                    }
                  >
                    <ListItemIcon className="subMenu_icon">
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </>
        ) : (
          <ListItemButton
            component={Link}
            to={"login"}
            onClick={toggleDrawer(false)}
          >
            <ListItemText primary="LOGIN / REGISTER" />
          </ListItemButton>
        )}

        {matches ? (
          <ListItemButton
            onClick={() =>
              setMenuOpen({ ...menuOpen, search: !menuOpen.search })
            }
          >
            <ListItemText primary="SEARCH" />
            <SearchIcon style={{ color: "#fff" }} />
          </ListItemButton>
        ) : null}

        <Collapse in={menuOpen.search} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className="subMenu_list">
            <ListItemButton sx={{ pl: 4 }}>
              <input
                placeholder="SEARCH HERE..."
                type="text"
                className={classes.search_input}
              />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          className={menuOpen.language ? "menu_item" : ""}
         
        >
          <ListItemText primary="LANGUAGE" />
          {menuOpen.language ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={menuOpen.language} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            className="subMenu_list"
            onClick={toggleDrawer(false)}
          >
            {nav_children_obj.language.map((item) => (
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText primary={item} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>
      <List
        style={{
          position: "fixed",
          bottom: 0,
          background: "#191b1bf7",
          width: "300px",
        }}
      >
        {IsAuthAllow() && (
          <ListItemButton
            component={Link}
            to={"edit_profile"}
            onClick={toggleDrawer(false)}
          >
            <ListItemIcon>
              <Avatar
                sx={{ bgcolor: "var(--auto-text-danger-alpha)" }}
                src="/broken-image.jpg"
              />
            </ListItemIcon>
            <ListItemText primary="Edit Profile" />
          </ListItemButton>
        )}
      </List>
    </Box>
  );

  const sideDrawer = (
    <Drawer
      open={menuDrawerOpen}
      onClose={toggleDrawer(false)}
      PaperProps={{ style: { backgroundColor: "rgb(23 25 25 / 98%)" } }}
    >
      {list}
    </Drawer>
  );

  return (
    
    <header
      id="header"
      className={classes.header}
      style={
        pathname === "/"
          ? {}
          : { overflow: "hidden", height: "auto", minHeight: "60px" }
      }
    >
      <div className="header_wrapper container">
        {matches ? (
          <div className="logo_wrapper">
            <Link to="/">
              <img className={classes.logo} src={Logo} alt="logo" />
            </Link>
          </div>
        ) : (
          <div className="mobile_header">
            <div className="submenubar-icon" onClick={toggleDrawer(true)}>
              {menuDrawerOpen ? <FaTimes /> : <FaBars />}
            </div>
            <div className="logo_wrapper">
              <Link to="/">
                <img className={classes.logo} src={Logo} alt="logo" />
              </Link>
            </div>
            <PopupState variant="popover">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    {...bindTrigger(popupState)}
                    className="search-button"
                  >
                    <SearchIcon
                      style={{
                        height: "35px",
                        width: "35px",
                        color: "#ffdd40",
                      }}
                    />
                  </Button>
                  <Menu
                    sx={{ p: 0, m: 0 }}
                    {...bindMenu(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: -180,
                    }}
                  >
                    <MenuItem
                      disableRipple
                      sx={{ px: 0, py: 0, m: 0 }}
                      className="search_wrapper"
                    >
                      <input
                        placeholder="SEARCH HERE..."
                        type="text"
                        className={classes.search_input}
                      />
                    </MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </div>
        )}

        <div className="menu_wrapper">
          <Box display="flex" alignItems="center">
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        {...bindTrigger(popupState)}
                        className="nav_item"
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        BUY
                      </Button>
                      <Menu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {nav_children_obj.buy.map((v, id) => {
                          return (
                            <MenuItem
                              component={Link}
                              to={v.link}
                              key={id}
                              onClick={popupState.close}
                              className={classes.MenuItem}
                            >
                              {v.name}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </li>
              <li className="nav-item">
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <Button {...bindTrigger(popupState)} className="nav_item">
                        SELL <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {nav_children_obj.sell.map((v, id) => {
                          return (
                            <MenuItem
                              component={Link}
                              to={v.link}
                              key={id}
                              className={classes.MenuItem}
                              onClick={popupState.close}
                            >
                              {v.name}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </li>
              <li className="nav-item">
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <Button {...bindTrigger(popupState)} className="nav_item">
                        LOOK FOR DEALER <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {nav_children_obj.look_for_dealer.map((v, id) => {
                          return (
                            <MenuItem
                              key={id}
                              onClick={popupState.close}
                              component={Link}
                              className={classes.MenuItem}
                              to={v.link}
                            >
                              {v.name}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </li>
              <li className="nav-item">
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <Button {...bindTrigger(popupState)} className="nav_item">
                        LOOK FOR GARAGE <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {nav_children_obj.look_for_garage.map((v, id) => {
                          return (
                            <MenuItem
                              key={id}
                              onClick={popupState.close}
                              component={Link}
                              to={v.link}
                              className={classes.MenuItem}
                            >
                              {v.name}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </li>
              <li className="nav-item">
                <Button component={Link} to="contact_us" className="nav_item">
                  CONTACT{" "}
                </Button>
              </li>
              {IsAuthAllow() ? (
                <li className="nav-item">
                  <PopupState variant="popover">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          {...bindTrigger(popupState)}
                          className="nav_item"
                        >
                          <DashboardIcon
                            sx={{ marginRight: 1 }}
                          ></DashboardIcon>{" "}
                          DASHBOARD
                        </Button>
                        <Menu
                          {...bindMenu(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          {nav_children_obj.dashboard.map((v, id) => {
                            return (
                              <MenuItem
                                key={id}
                                component={Link}
                                to={v.link}
                                className={classes.MenuItem}
                                onClick={
                                  v.name === "Logout"
                                    ? () => removeLocalStorage()
                                    : popupState.close
                                }
                              >
                                {v.name}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </li>
              ) : (
                <li className="nav-item">
                  <Button className="nav_item" to="login" component={Link}>
                    <AccountCircleIcon></AccountCircleIcon> LOGIN/REGISTER
                  </Button>
                </li>
              )}

              <li className="search_list_item nav-item">
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <Button {...bindTrigger(popupState)} className="nav_item">
                        <SearchIcon />
                      </Button>
                      <Menu
                        sx={{ p: 0, m: 0 }}
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: -180,
                        }}
                      >
                        <MenuItem
                          disableRipple
                          sx={{ px: 0, py: 0, m: 0 }}
                          className="search_wrapper"
                        >
                          <input
                            placeholder="SEARCH HERE..."
                            type="text"
                            className={classes.search_input}
                          />
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </li>
              <li className="nav-item">
                <PopupState variant="popover">
                  {(popupState) => (
                    <React.Fragment>
                      <Button {...bindTrigger(popupState)} className="nav_item">
                        {lang} <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                       
                      >
                        {nav_children_obj.language.map((v, id) => {
                          return (
                            <MenuItem
                              key={id}
                              onClick={() => {
                                handleLanguageClick(v);
                                popupState.close();
                              }}
                              className={classes.MenuItem}
                            >
                              {v}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </li>
            </ul>
            {IsAuthAllow() ? (
              <Link
                to="/edit_profile"
                style={{ marginLeft: "8px" }}
                className="nav-menu"
              >
                <Stack spacing={2}>
                  <Avatar
                    sx={{ bgcolor: "var(--auto-text-danger-alpha)" }}
                    src="/broken-image.jpg"
                  />
                </Stack>
              </Link>
            ) : null}
          </Box>
        </div>
      </div>
      {sideDrawer}
      
    </header>
   
  );
}
const mapStateToProps = (state) => ({
  info: state.rootReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setdata: (data) => dispatch(getLangaugeType(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);

