import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import "./Homepage.scss";
import { Typography, Button, useMediaQuery } from "@mui/material";
import OverlapBox from "./Partial/OverlapBox";
import OverlapBox2 from "./Partial/OverlapBox2";
import Slider from "../../Components/Slider/Slider";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from 'react-intl';

const PaddingBox = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down("md")]: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "60px",
    paddingRight: "60px",
  },
}));

export default function Homepage(props) {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <div id="homepage">
      <Box component="section" className="overlap_section">
        <Container fixed style={{ paddingInline: matches ? null : "5px" }}>
          <Box>
            <PaddingBox>
              <Typography
                align="center"
                variant="h3"
                style={{ marginBottom: "15px" }}
              >
                <FormattedMessage id="Home-Page-Title" />
                
              </Typography>
            </PaddingBox>

            <OverlapBox></OverlapBox>
          </Box>
        </Container>
        <OverlapBox2></OverlapBox2>
      </Box>

      <Box component="section" id="section2">
        <div className="content_wrapper">
          <h2><FormattedMessage id="Rent-Or-Sale" /></h2>
          <p><FormattedMessage id="The-Rental" /></p>
          <Button><FormattedMessage id="Look-For-Garage" /></Button>
        </div>
        <div className="homepage_ad">
          Local <br /> Advertisement <br /> Will <br /> Be <br /> Here
        </div>
      </Box>

      <Box component="section" id="section3">
        <Slider1></Slider1>
        <Slider2></Slider2>
      </Box>
    </div>
  );
}

const Slider1 = () => {
  const sliderObject = {
    slider_name: "ALL CARS BRAND",
    slider_items: [
      {
        id: 1,
        img: require("../../static/img/lance-asper-N9Pf2J656aQ-unsplash.jpg"),
        title: "MUSTANG GT",
        link: "#",
      },
      {
        id: 2,
        img: require("../../static/img/benjamin-child-7Cdw956mZ4w-unsplash.jpg"),
        title: "BMW M3",
        link: "",
      },
      {
        id: 3,
        img: require("../../static/img/jose-carbajal-8xyki0bqvgw-unsplash.jpg"),
        title: "VOLKSWAGEN",
        link: "",
      },
      {
        id: 4,
        img: require("../../static/img/jose-carbajal-8xyki0bqvgw-unsplash.jpg"),
        title: "VOLKSWAGEN",
        link: "",
      },
    ],
  };
  return <Slider sliderName="cars_brand" sliderObject={sliderObject}></Slider>;
};

const Slider2 = () => {
  const sliderObject = {
    slider_name: "ALL BIKES BRAND",
    slider_items: [
      {
        id: 1,
        img: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
        title: "HARLEY 975",
        link: "#",
      },
      {
        id: 2,
        img: require("../../static/img/pairach-boriboonmee-PDMR0_IUjro-unsplash.jpg"),
        title: "KAWASAKI",
        link: "",
      },
      {
        id: 3,
        img: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
        title: "HARLEY",
        link: "",
      },
      {
        id: 4,
        img: require("../../static/img/harley-davidson-YsMg1pJqqKk-unsplash.jpg"),
        title: "HARLEY",
        link: "",
      },
    ],
  };
  return <Slider sliderName="bikes_brand" sliderObject={sliderObject}></Slider>;
};
