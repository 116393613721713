import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Button } from "@mui/material";
import Fab from "@mui/material/Fab";
import "./SocialComponent.css";
function SocialComponent(props) {
  return (
    <div id="SocialComponent">
      <Button component="a" sx={{mr: 3}} className="" disableRipple href="#">
        <Fab color="white" className="fb_fab"  size="small" aria-label="add">
          <FacebookIcon></FacebookIcon>
        </Fab>
      </Button>
      <Button component="a" sx={{mr: 3}} disableRipple href="#">
        <Fab color="white" className="twitter_fab"  size="small" aria-label="add">
          <TwitterIcon></TwitterIcon>
        </Fab>
      </Button>
      <Button component="a" href="#" disableRipple>
        <Fab color="white" className="instragram_fab"  size="small"  aria-label="add">
          <InstagramIcon />
        </Fab>
      </Button>
    </div>
  );
}

export default SocialComponent;
