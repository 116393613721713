// const InitState = {
//   token:""
// }


// const getLoginuserToken = (state = InitState, action) => {

//   switch (action.type) {
    
//     case 'GET_LOGIN_TOKEN':
//       return {
//         ...state,
//         token: action.payload
//       }

//     default:
//       return state
//   }
// }



import { ActionTypes } from "../Action/loginaction";
const initialState = {
    isLoggedIn: JSON.parse(localStorage.getItem("is_active")) ?? false,
    user: JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : null
}

export const authReduce = (state=initialState,{type,payload})=>{
    switch(type){
        case ActionTypes.USER_LOGIN_SUCCESS:
            return {...state,isLoggedIn : true,user : payload}
        case ActionTypes.USER_LOGIN_FAILURE:
            return {isLoggedIn : false}
        case ActionTypes.USER_LOGOUT:
            return {isLoggedIn : false}
        default :
            return state;
    }
}

