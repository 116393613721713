import React from "react";
import "./Dealer.scss";

import { Box, Grid, Paper, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductMaterialCard from "../../Components/ProductMaterialCard/ProductMaterialCard";
import { ProductMaterialCard2 } from "../../Components/ProductMaterialCard/ProductMaterialCard";
import "../../static/css/forms_style/forms_style.css";
import { FormattedMessage } from 'react-intl';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Dealer(props) {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <div id="Dealer">
      <div className="Dealer_header">
        <h3 className="cyan_style" style={{ width: matches ? "50%" : "70%" }}>
          
          <FormattedMessage id="Mercant-Search" />
        </h3>
      </div>
      <div className="Dealer_body">
        <Section1></Section1>
        <Section2></Section2>
      </div>
    </div>
  );
}

export default Dealer;

function Section1() {
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //     setAge(event.target.value);
  // };

  // // search filter

  // const [searchFilter, setSearchFilter] = React.useState('');
  // const handleChangeSearchFilter = (event) => {
  //     setSearchFilter(event.target.value);
  // };

  // // sorting
  // const [sorting, setSorting] = React.useState('');
  // const handleChangeSorting = (event) => {
  //     setSorting(event.target.value);
  // };

  // // price filter
  // const [priceFilter, setPriceFilter] = React.useState('');
  // const handleChangePriceFilter = (event) => {
  //     setPriceFilter(event.target.value);
  // };
  return (
    <section className="section1">
      <Box className="box_content">
        <form action="" className="form_search">
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 2, sm: 4, md: 6 }}
          >
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <input
                    type="text"
                    className="form_control"
                    placeholder="Dealer name..."
                  />
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      Brand
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      Country
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      State
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      PostCode/Town
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} md={4}>
              <Item
                sx={{ boxShadow: 0, p: 0, borderRadius: "var(--radius-pill)" }}
              >
                <Box className="form_group">
                  <select className="select_control" defaultValue="s">
                    <option value="s" disabled>
                      Radius
                    </option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sx={{ mt: 4, mb: 4 }}>
              <Item sx={{ boxShadow: 0, p: 0, background: "transparent" }}>
                <Box>
                  <Button
                    className="cyan_style"
                    sx={{ width: "50%", fontSize: 23 }}
                  >
                  
                    <FormattedMessage id="Search" />
                  </Button>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </form>
      </Box>
    </section>
  );
}

function Section2() {
  const cardObject = [
    {
      id: 1,
      img: require("../../static/img/1623152459_merchant.png"),
      title: "KUMAWAT BANWARI",
      link: "#",
      city: "BARMELWEID",
      postal_code: "5017",
    },
    {
      id: 2,
      img: require("../../static/img/53.jpg"),
      title: "KUMAWAT BANWARI",
      link: "#",
      city: "BARMELWEID",
      postal_code: "5017",
    },
  ];
  return (
    <section id="section2">
      <Box>
        <h2
          className="title_line section_title"
          style={{ display: "inline-block" }}
        >
         
          <FormattedMessage id="Merchants" />
        </h2>

        <Box>
          <ProductMaterialCard2 cardObject={cardObject}></ProductMaterialCard2>
        </Box>
      </Box>
    </section>
  );
}
