import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import companyLogo from "../../assets/images/CommingSoon.png";
import "./CommingSoon.scss";
import Footer from "../../Layout/Footer/Footer";

export default function CommingSoon() {
  return (
    <div>
      <img src={companyLogo} alt="BigCo Inc. logo" className="w-100" />
      <Footer></Footer>
    </div>
  );
}
