import React, { useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./BikeAdvertisement.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function BikeAdvertisement(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div id="BikeAdvertisement">
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
    
          <FormattedMessage id="Advertisement-Packages"/>
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{
          pb: 8,
          background: "var(--auto-text-f1)",
          ml: matches ? "260px" : "0px",
        }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: 6 }}>
          <CarPackageAd></CarPackageAd>
        </Box>
      </Box>
    </div>
  );
}

export default BikeAdvertisement;

const CarPackageAd = () => {
  const package_object = [
    {
      id: 1,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
    {
      id: 2,
      title: "ATB15D",
      cost: "30 CHF",
      period: "1-15 DAY",
      images: "UP TO 7",
      per_car_cost: " UP TO 15 & 50 CHF",
      images_count: 1,
    },
  ];

  return (
    <Grid container rowSpacing={6} columnSpacing={{ xs: 3, sm: 6 }}>
     {package_object.map((v) => (
              <Grid item xs={12} sm={6} key={v.id}>
                <Item sx={{ boxShadow: 3, borderRadius: 4 }}>
                  <Box>
                    <h2 className="title_line">{v.title}</h2>
                  </Box>
                  <Box>
                    <table className="package_table table_form">
                      <tbody>
                        <tr>
                          <td><FormattedMessage id="EACH-VECHILE-COSTS"/></td>
                          <td>
                            <div className="cyan_value_box">{v.cost}</div>
                          </td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id="PERIOD"/></td>
                          <td>
                            <div className="cyan_value_box">{v.period}</div>
                          </td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id="IMAGES"/></td>
                          <td>
                            <div className="cyan_value_box">{v.images}</div>
                          </td>
                        </tr>
                        <tr>
                          <td><FormattedMessage id="More-Pictures"/></td>
                          <td>
                            <div className="cyan_value_box">
                              {v.per_car_cost}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td><FormattedMessage id="IMAGES-COUNT"/></td>
                          <td>
                            <div className="cyan_input_box">
                              <div className="form_group">
                                <select
                                  defaultValue="s"
                                  className="select_control"
                                >
                                  <option value={v.images_count}>
                                    {v.images_count}
                                  </option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                </select>
                                <KeyboardArrowDownIcon className="form_icon"></KeyboardArrowDownIcon>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>

                  <Box sx={{ my: 3 }}>
                    <Button sx={{ py: 0, px: 3 }} className="btn_cyan_style">
                 
                      <FormattedMessage id="Take-Package" /> 
                    </Button>
                  </Box>
                </Item>
              </Grid>
            ))}
    </Grid>
  );
};
