import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./UserPanel.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { FormattedMessage } from 'react-intl';
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function UserPanel(props) {
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <div
      id="UserPanel"
      style={{
        marginLeft: matches ? "270px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
       
          <FormattedMessage id="User-Panel"/>
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{
          pb: 8,
          background: "var(--auto-text-f1)",
        }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 6 : 2 }}>
          <UserPanelItem></UserPanelItem>
        </Box>
      </Box>
    </div>
  );
}

export default UserPanel;

const UserPanelItem = () => {
  const panel_object = [
    {
      id: 1,
      icon: <DirectionsCarIcon></DirectionsCarIcon>,
      name: <FormattedMessage id="Car-Posted"/>,
      count: 8,
      link: "",
    },
    {
      id: 2,
      icon: <TwoWheelerIcon></TwoWheelerIcon>,
      name: <FormattedMessage id="Bike-Posted"/>,
      count: 8,
      link: "",
    },
    {
      id: 3,
      icon: <DirectionsCarIcon></DirectionsCarIcon>,
      name: <FormattedMessage id="Ads-Count"/>,
      count: 8,
      link: "",
    },
    {
      id: 4,
      icon: <DirectionsCarIcon></DirectionsCarIcon>,
      name: <FormattedMessage id="Car-Component-Count"/>,
      count: 8,
      link: "",
    },
    {
      id: 5,
      icon: <DirectionsCarIcon></DirectionsCarIcon>,
      name: <FormattedMessage id="Bike-Component-Count"/>,
      count: 8,
      link: "",
    },

    {
      id: 6,
      icon: <DirectionsCarIcon></DirectionsCarIcon>,
      name: <FormattedMessage id="Merchant-Count"/>,
      count: 8,
      link: "",
    },
    {
      id: 7,
      icon: <DirectionsCarIcon></DirectionsCarIcon>,
      name: <FormattedMessage id="Garage-Count"/>,
      count: 8,
      link: "",
    },
  ];
  return (
    <Grid container rowSpacing={6} columnSpacing={{ xs: 3, sm: 6 }}>
      {panel_object.map((v) => (
        <Grid item xs={12} md={6} key={v.id}>
          <Item
            className="box_item"
            sx={{ boxShadow: 3, p: 0 }}
            component={Link}
            to={v.link}
          >
            <Box className="icon_box">{v.icon}</Box>
            <Box className="text_box">
              {v.name} <br /> {v.count}
            </Box>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};
