import React from "react";
import "./PictureUploadProfile.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { CircularProgress } from "@mui/material";
function PictureUploadProfile({
  handleUploader,
  profile_pic,
  profilePicLoading,
}) {
  return (
    <label htmlFor="Vehicle_rc_input" id="PictureUploadProfile">
      {profile_pic ? (
        profilePicLoading ? (
          <>
            <CircularProgress
              style={{
                position: "absolute",
                width: "200px",
                height: "200px",
                background: "#0000009e",
                "border-radius": "50%",
              }}
            />
            <img
              src={profile_pic}
              alt="Autoby24 profile pic"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            ></img>
          </>
        ) : (
          <img
            src={profile_pic}
            alt="Autoby24 profile pic"
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          ></img>
        )
      ) : (
        <>
          <AccountCircleIcon fontSize="large"></AccountCircleIcon>UPLOAD PROFILE
        </>
      )}
    </label>
  );
}

export default PictureUploadProfile;
