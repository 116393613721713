import React from "react";
import "./ProductSlider.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Box } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

function ProductSlider({
  sliderObject,
  cardExtraContent = true,
  cardExtraPadding = "0",
  sliderName,
}) {
  //console.log(sliderObject);

  const FavoriteIconHandle = (event) => {
    event.target.classList.toggle('favorite_active')
  };
  return (
    <div id="ProductSlider">
      <div className="slider_wrapper">
        <h3 id="slider_name">{sliderObject.slider_name}</h3>

        <Box position="relative" className="slider_swiper_box">
          <Swiper
            navigation={true}
            className="car_brands_swiper"
            navigation={{
              //
              nextEl: `.custom_swiper_navigate_next_${sliderName}`, //Next
              prevEl: `.custom_swiper_navigate_prev_${sliderName}`, //Prev
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
          >
            {sliderObject.slider_items.map((v, i) => {
              return (
                <SwiperSlide key={v.id} className="swiper_slide">
                  <Card sx={{ maxWidth: "100%" }}>
                    <CardActionArea component="div">
                      <Box height="220" position="relative" component="div">
                        <CardMedia
                          component="img"
                          height="100%"
                          image={v.img}
                          alt="img"
                        />
                        <Button className="price_btn"> {v.price} </Button>
                      </Box>

                      <CardContent
                        sx={{
                          position: "relative",
                          m: 0,
                          p: 0,
                          py: cardExtraPadding,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          color="black"
                          sx={{ fontWeight: "bold", mb: 0 }}
                          align="center"
                        >
                          {v.title}
                        </Typography>
                        <FavoriteBorderIcon
                          onClick={FavoriteIconHandle}
                          sx={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        ></FavoriteBorderIcon>
                      </CardContent>
                      {cardExtraContent && (
                        <CardContent sx={{ position: "relative", m: 0, py: 1, px:0 }}>
                          <Box className="content_info_box">
                            <span> <img style={{height: '12px'}} src={require('../../static/icons/329Z.png')} alt="" /> Automatic</span>
                            <span>{v.automatic}</span>
                          </Box>
                          <Box className="content_info_box">
                            <span>Used</span>
                            <span>{v.used}</span>
                          </Box>
                        </CardContent>
                      )}
                    </CardActionArea>
                    <CardActions sx={{px:0}}>
                      <Button
                        size="large"
                        sx={{ width: "100%", borderRadius: 5 }}
                        className="btn_view_more"
                      >
                        View more{" "}
                        <ArrowForwardIcon
                          sx={{ marginLeft: 1 }}
                        ></ArrowForwardIcon>
                      </Button>
                    </CardActions>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div
            className={`swiper-button-prev custom_swiper_navigate_prev_${sliderName}`}
          ></div>
          <div
            className={`swiper-button-next custom_swiper_navigate_next_${sliderName}`}
          ></div>
        </Box>
      </div>
    </div>
  );
}

export default ProductSlider;
