import React, { useEffect, useState } from "react";
import "./CustomizePakage.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, useMediaQuery } from "@mui/material";
import { createCustomizePackage } from "../../static/http-content";
import { toast } from "react-toastify";
import { useIntl } from 'react-intl';
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function CustomizePakage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [customizePakage, setCustomizePakage] = useState({
    number_of_vehicle: null,
    number_of_images: null,
    validity_days: null,
    description: null,
    package_name: null
  });

  useEffect(() => {
    // if (carId) {
    //   getPackagesListHttpRequest
    //     .get(`/${carId}/`)
    //     .then((res) => {

    //     })
    //     .catch((err) => console.log(err.response));
    // }
  }, []);

  const submit = () => {
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let obj = {
      number_of_vehicle: customizePakage?.number_of_vehicle,
      number_of_images: customizePakage?.number_of_images,
      validity_days: customizePakage?.validity_days,
      description: customizePakage?.description,
      package_name: customizePakage?.package_name
    }

    createCustomizePackage
      .post("/", obj, {
        headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        toast.update(
          toast_id,
          {
            render: "Custom Package Created Successfully",
            type: 'success',
            isLoading: false,
            closeOnClick: true,
            autoClose: 5000,
            pauseOnHover: true,
          }
        );
        navigate(`/sell_car1`);
      })
      .catch((err) => {
        toast.update(
          toast_id,
          {
            render: err?.response?.data?.errors,
            type: 'error',
            isLoading: false,
            closeOnClick: true,
            autoClose: 5000,
            pauseOnHover: true,
          }
        );
        console.log(err.response);
      });

  };

  return (
    <div id="SellCar2">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan"><FormattedMessage id="Customize-Package"/></h3>
        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <FormBox1
                customizePakage={customizePakage}
                setCustomizePakage={setCustomizePakage}
              ></FormBox1>
              <FormSubmit submit={submit} />
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default CustomizePakage;

const FormBox1 = (props) => {
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line"><FormattedMessage id="Customize-Package"/></h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Package-Name"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Package Name..."
                  value={props?.customizePakage?.package_name}
                  onChange={(e) => {
                    props.setCustomizePakage({
                      ...props.customizePakage,
                      package_name: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Number-Of-Vehicle"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Number Of Vehicle..."
                  value={props?.customizePakage?.number_of_vehicle}
                  onChange={(e) => {
                    props.setCustomizePakage({
                      ...props.customizePakage,
                      number_of_vehicle: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Number-Of-Images"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Number Of Images..."
                  value={props?.customizePakage?.number_of_images}
                  onChange={(e) => {
                    props.setCustomizePakage({
                      ...props.customizePakage,
                      number_of_images: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Validity-Days"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Validity Days..."
                  value={props?.customizePakage?.validity_days}
                  onChange={(e) => {
                    props.setCustomizePakage({
                      ...props.customizePakage,
                      validity_days: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Description"/>: {" "}
                </label>
                <textarea
                  type="text"
                  className="form_control"
                  placeholder="Description..."
                  rows="9"
                  value={props?.customizePakage?.description}
                  onChange={(e) => {
                    props.setCustomizePakage({
                      ...props.customizePakage,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSubmit = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 3 }}>
      <Button type="button" sx={{ px: 6 }} className="btn_cyan_style" onClick={props.submit}>
     
        <FormattedMessage id="SEND"/>
      </Button>
      <Button type="button" sx={{ px: 3, ml: 2 }} className="btn_cyan_style"
        onClick={() => navigate(-1)}>
          {/* /sell_car1 */}
          <FormattedMessage id="Back"/>
       
      </Button>
    </Box>
  );
};
