import React, { useState, useRef, useEffect } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import UserPanelAside from "../../Components/UserPanelAside/UserPanelAside";
import "./VipAdvertisementHistory.scss";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import {
  getAllPackagesHttpRequest,
  downloadCsvHttpRequest,
  importCSVFileHttpRequest,
  importExcelFileHttpRequest,
  getAdvertismentListHttpRequest,
  deleteAdvertismentHttpRequest,
  activateInactiveVehicleHttpRequest,
} from "../../static/http-content";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));
function VipAdvertisementHistory(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [isDisable, setDisable] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const [showSideBar, setShowSideBar] = useState(false);
  const [packageData, setPackageData] = useState([]);
  const [packageDetail, setPackageDetail] = useState(null);
  useEffect(() => {
    getAllPackagesHttpRequest
      .get(`/${id}/`)
      .then((res) => {
        setPackageDetail(res?.data?.data);
        getAdvertismentListHttpRequest
          .get("/vip", {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setPackageData(res.data.data);
            if (res?.data?.data.length === packageDetail?.number_of_vehicle) {
              setDisable(true);
            }
          })
          .catch((err) => console.log(err.response));
      })
      .catch((err) => console.log(err.response));
  }, []);
  return (
    <div
      id="AdvertisementHistory"
      style={{
        marginLeft: matches ? "260px" : "0px",
      }}
    >
      <Box sx={{ my: 3 }}>
        <h3
          className="section_title_cyan max_size_cyan"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            maxWidth: "350px",
          }}
        >
          {matches ? null : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() => setShowSideBar(true)}
            />
          )}
          VIP Advertisement History
        </h3>
      </Box>
      <Box
        display="flex"
        sx={{ pb: 8, background: "var(--auto-text-f1)" }}
        className="full_content_wrapper"
      >
        <UserPanelAside
          showSideBar={showSideBar}
          setShowSideBar={setShowSideBar}
        />
        <Box flexGrow="1" sx={{ p: matches ? 6 : 1, maxWidth: "100%" }}>
          <Box
            id="historyBox"
            sx={{
              boxShadow: 3,
              p: 6,
              borderRadius: 3,
              background: "var(--auto-text-white)",
            }}
          >
            <BoxHead
              isDisable={isDisable}
              setDisable={setDisable}
              packageDetail={packageDetail}
              setPackageDetail={setPackageDetail}
            ></BoxHead>
            <BoxBody
              packageData={packageData}
              setPackageData={setPackageData}
            ></BoxBody>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default VipAdvertisementHistory;

const BoxHead = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const intl = useIntl();
  const inputRef1 = useRef(null);
  const inputRef = useRef(null);
  const matches = useMediaQuery("(min-width:600px)");

  const [Import, setImport] = useState(0);
  const [Export, setExport] = useState(0);
  const handleChange = (event) => {
    setImport(event.target.value);
    if (event.target.value == 10) {
      downloadCsvHttpRequest
        .get(
          `/?_export=yes&export_format=csv&package_subscription__package=${id}`,
          {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "vehicle.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => console.log(err.response));
    } else if (event.target.value == 20) {
      downloadCsvHttpRequest
        .get(
          `/?_export=yes&export_format=xlsx&package_subscription__package=${id}`,
          {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "vehicle.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => console.log(err.response));
    }
  };
  const handleChanges = (event) => {
    setExport(event.target.value);
    if (event.target.value == 10) {
      inputRef.current?.click();
    } else if (event.target.value == 20) {
      inputRef1.current?.click();
    }
  };
  const changeCsvHandler = () => {
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let formData = new FormData();
    formData.append("file", inputRef.current?.files[0]);
    formData.append("package", id);
    importCSVFileHttpRequest
      .post("/", formData, {
        headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        getAllPackagesHttpRequest
          .get(`/${id}/`, {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            props?.setPackageDetail(res?.data?.data);
            getAdvertismentListHttpRequest
              .get("/local", {
                headers: {
                  Authorization: `jwt ${localStorage.getItem("token")}`,
                },
              })
              .then((res) => {
                props?.setPackageData(res.data.data);
                if (
                  res?.data?.data.length ===
                  props?.packageDetail?.number_of_vehicle
                ) {
                  props?.setDisable(true);
                }
              })
              .catch((err) => console.log(err.response));
          })
          .catch((err) => console.log(err.response));
        toast.update(toast_id, {
          render: "CSV Import Successfully",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err.response));
  };
  const changeExcelHandler = () => {
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let formData = new FormData();
    formData.append("file", inputRef1.current?.files[0]);
    formData.append("package", id);
    importExcelFileHttpRequest
      .get("/", formData, {
        headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        getAllPackagesHttpRequest
          .get(`/${id}/`, {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            props?.setPackageDetail(res?.data?.data);
            getAdvertismentListHttpRequest
              .get("/local", {
                headers: {
                  Authorization: `jwt ${localStorage.getItem("token")}`,
                },
              })
              .then((res) => {
                props?.setPackageData(res.data.data);
                if (
                  res?.data?.data.length ===
                  props?.packageDetail?.number_of_vehicle
                ) {
                  props?.setDisable(true);
                }
              })
              .catch((err) => console.log(err.response));
          })
          .catch((err) => console.log(err.response));
        toast.update(toast_id, {
          render: "Excel Import Successfully",
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <Box id="BoxHead">
      <form action="" className="responsiveBoxHead form_history_search">
        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">Package Name:</div>
          <div>{props?.packageDetail?.package_name}</div>
        </Box>

        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">From Date:</div>
          <HistoryDate></HistoryDate>
        </Box>

        <Box className="form_group" sx={{ mr: 3 }}>
          <div className="form_label">To Date:</div>
          <HistoryDate></HistoryDate>
        </Box>
        {/* <Box className="form_group" sx={{ justifyContent: "flex-end" }}>
          <Button
            className="btn_cyan_style"
            sx={{ py: 0.5, px: 3, width: matches ? "auto" : "100%" }}
          >
            SUBMIT
          </Button>
        </Box> */}
      </form>
      <Box className="btn_download_wrapper" sx={{ my: 3, display: "flex" }}>
        {/* <Button
          type="button"
          className="btn_cyan_style"
          sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
        >
          <DownloadIcon></DownloadIcon>
          CSV
        </Button>
        <Button
          type="button"
          className="btn_cyan_style"
          sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
        >
          <DownloadIcon></DownloadIcon>
          Excel
        </Button> */}
        <Button
          type="button"
          className="btn_cyan_style"
          sx={{ fontSize: "large", py: 0, px: 2, mr: 1 }}
          component={Link}
          to={`/add_vip_advertisement?id=${id}`}
          disabled={props.isDisable}
        >
          <AddIcon />
          Add VIP Advertisement
        </Button>
        {/* <Button
          type="button"
          className="btn_cyan_style"
          sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
          disabled={props.isDisable}
        >
          <CloudDownloadIcon></CloudDownloadIcon>
          Import CSV
        </Button>
        <Button
          type="button"
          className="btn_cyan_style"
          sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
          disabled={props.isDisable}
        >
          <CloudDownloadIcon></CloudDownloadIcon>
          Import Excel
        </Button>
        <Button
          type="button"
          className="btn_cyan_style"
          sx={{ fontSize: "large", py: 0, px: 2, mr: 3, mb: 3 }}
        >
          Download History
        </Button> */}
        <Box sx={{ mr: 1, justifyContent: "end" }}>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{
                background: "#00aeef",
                color: "#fff",
                borderRadius: "20px",
              }}
              SelectDisplayProps={{
                style: { paddingTop: 4, paddingBottom: 4 },
              }}
              value={Import}
              className="form_group icon-white"
              label="Export"
              onChange={handleChange}
            >
              <MenuItem value={0} style={{ display: "none" }}>
                Export
              </MenuItem>
              <MenuItem value={10}>CSV</MenuItem>
              <MenuItem value={20}>Excel</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{
                background: "#00aeef",
                color: "#fff",
                borderRadius: "20px",
              }}
              SelectDisplayProps={{
                style: { paddingTop: 4, paddingBottom: 4 },
              }}
              value={Export}
              className="form_group icon-white"
              label="Import"
              onChange={handleChanges}
            >
              <MenuItem value={0} style={{ display: "none" }}>
                Import
              </MenuItem>
              <MenuItem value={10}>CSV</MenuItem>
              <MenuItem value={20}>Excel</MenuItem>
            </Select>
          </FormControl>
          <input
            ref={inputRef}
            type="file"
            name="file"
            accept=".csv"
            onChange={changeCsvHandler}
            style={{ display: "none", margin: "10px auto" }}
          />
          <input
            ref={inputRef1}
            type="file"
            name="file"
            accept=".xlsx"
            onChange={changeExcelHandler}
            style={{ display: "none", margin: "10px auto" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const HistoryDate = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <DatePicker
      className="datePicker"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
};

const BoxBody = (props) => {
  const matches = useMediaQuery("(min-width:600px)");
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const inactive = (vehicle) => {
    activateInactiveVehicleHttpRequest
      .post(
        "/",
        { vehicle_id: vehicle },
        {
          headers: {
            Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
          },
        }
      )
      .then((res) => {
        getAdvertismentListHttpRequest
          .get("/vip", {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            props.setPackageData(res.data.data);
          })
          .catch((err) => console.log(err.response));
      })
      .catch((err) => console.log(err.response));
  };

  const submit = (ids) => {
    deleteAdvertismentHttpRequest
      .delete(`/${ids}`)
      .then((res) => {
        getAdvertismentListHttpRequest
          .get("/vip", {
            headers: {
              Authorization: `jwt ${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            props.setPackageData(res.data.data);
          })
          .catch((err) => console.log(err.response));
      })
      .catch((err) => console.log(err.response));
  };
  return (
    <>
      {matches ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="history_table"
            className="history_table"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ fontStyle: "italic" }}>#</TableCell>
                <TableCell align="center">IMAGE</TableCell>
                <TableCell align="center">
                  ACTIVE <br /> DATE
                </TableCell>
                <TableCell align="center">
                  EXPIRE <br /> DATE
                </TableCell>
                <TableCell align="right">STATUS</TableCell>
                <TableCell align="right">ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.packageData?.length > 0 &&
                props.packageData?.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className="row_id" component="td" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <Box
                          component="img"
                          alt="image"
                          className="history_image"
                          src={row?.images[0]?.image}
                          sx={{ boxShadow: 3 }}
                        ></Box>
                      </TableCell>
                      <TableCell
                        className="text_nowrap"
                        align="center"
                        sx={{ fontWeight: "bold" }}
                        component="td"
                      >
                        Jun 01, 2021
                        {/* {row.active_date} */}
                      </TableCell>
                      <TableCell
                        className="text_nowrap"
                        align="center"
                        sx={{ fontWeight: "bold" }}
                        component="td"
                      >
                        Jun 11, 2021
                        {/* {row.expire_date} */}
                      </TableCell>
                      <TableCell
                        component="td"
                        align="center"
                        sx={{ fontWeight: "bold" }}
                      >
                        <Button
                          component="span"
                          sx={{ textTransform: "capitalize", p: 0 }}
                          color={row.is_active ? "primary" : "error"}
                        >
                          {row.is_active ? "Active" : "Reactive"}
                        </Button>
                      </TableCell>
                      <TableCell align="center" component="td">
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <IconButton
                            type="button"
                            className="btn_cyan_style"
                            aria-label="delete"
                            sx={{
                              p: 1,
                              mb: 1,
                              borderRadius: "10px!important",
                            }}
                            size="large"
                            onClick={() => submit(row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>

                          <IconButton
                            className="btn_cyan_style"
                            aria-label="edit"
                            sx={{
                              p: 1,
                              mb: 1,
                              borderRadius: "10px!important",
                            }}
                            size="large"
                            component={Link}
                            to={`/add_vip_advertisement?id=${id}&edit=true&title=demo&price=25&ad_link=https://www.linkedin.com/feed&image=https://placehold.co/600x400`}
                          >
                            <EditIcon />
                          </IconButton>

                          {row.is_active ? null : (
                            <IconButton
                              type="button"
                              className="btn_cyan_style"
                              aria-label="editattributesicon"
                              sx={{ p: 1, borderRadius: "10px!important" }}
                              size="large"
                              onClick={() => inactive(row?.images[0]?.vehicle)}
                            >
                              <EditAttributesIcon />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </>
  );
};
