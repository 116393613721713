import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    //apiKey: "AIzaSyChktGYACm_gF3E1dEhpUdxDb-S_pf_3uU",
    apiKey: "AIzaSyAYV9wTqDGyq63c4VP46657-S_tsCBc3k8",

    //authDomain: "nstyle-361807.firebaseapp.com",
    authDomain: "autoby24-390314.firebaseapp.com",


    //projectId: "nstyle-361807",
    projectId: "autoby24-390314",

    // storageBucket: "autoby24-390314.appspot.com",
    // messagingSenderId: "1020221450785",
    appId: "1:1020221450785:web:832e62fe54e8d1d4089160",
    // measurementId: "G-9HS39T43Q2"
};
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}
// export const db = firebase.firestore()
export const GoogleAuthprovider = new firebase.auth.GoogleAuthProvider();

export default firebase