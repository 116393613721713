// export const getLoginuserToken = data => {
//     return {
//       type: 'GET_LOGIN_TOKEN',
//       payload: data
//     }
//   }

export const ActionTypes = {
  //Admin Login constants

  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  //Admin Logout constant
  USER_LOGOUT: "USER_LOGOUT",
}

