import { userRegisterHttpRequest,userLoginHttpRequest,BaseUrl, user_social_register_url, user_register_url, login_user_url, send_verify_code_email_urls, verify_user_otp_email_url, change_password_url } from "../../static/http-content";
import axios from 'axios';
import {useSelector} from  'react-redux';

export const RegisterNewUser = (reqData, success, fail) =>{
    axios({url:`${BaseUrl}${user_register_url}`,
              method : "POST",
            data : reqData,
   }).then((result) => {
    if (result.data){
        success && success({...result})
    }
        else{
            fail && fail(result.message)
        }
    })
    .catch((err) => {
        fail && fail(err.message)
    });
}

export const SocialRegisterNewUser = (reqData, success, fail) =>{
    axios({url:`${BaseUrl}${user_social_register_url}`,
              method : "POST",
            data : reqData,
   }).then((result) => {
    if (result.data){
        success && success({...result})
    }
        else{
            fail && fail(result.message)
        }
    })
    .catch((err) => {
        fail && fail(err.message)
    });
}

export const LoginUser = (reqData, success, fail) =>{

    let s_code
    axios({url:`${BaseUrl}${login_user_url}`,
              method : "POST",
            data : reqData,
   }).then((result) => {
      
        if (result.data.success == true){
            success && success({...result})
        }
        else{
            fail && fail(result.message)
        }
    })
    .catch((err) => {
        if(err)
        {
            
            console.log("err",err);
            
            fail && fail(err.message)
    
        }
           });
}

export const requestUserEmailOtp = (reqData, success, fail) =>{
    let s_code
    fetch(
        `${BaseUrl}${send_verify_code_email_urls}`,
        {
            method : 'POST',
            body : JSON.stringify(reqData),
            headers : {
                'Content-Type' : 'application/json'
            }
        }
    )
    
    .then((result) => {
        if (result.status == 200){
            success && success({...result})
        }
        else{
            fail && fail(result.message)
        }
    })
    .catch((err) => {
        fail && fail('Something went wrong')
    });
}

export const VerifyEmailOtp = (token,success, fail) =>{
    const headers = {
        'Authorization':`jwt ${token}`, 
        'Content-Type': 'application/json'
      };
    let s_code
    axios({
        url:`${BaseUrl}${verify_user_otp_email_url}`,
        headers:headers,
        method:"GET", 
    })   
    .then((result) => {
        if (result.status == 200){ 
            success && success({...result})
        }
        else{
            fail && fail(result.message)
        }
    })
    .catch((err) => {
        fail && fail('Something went wrong')
    });
}

export const ChangeEmailOtpPassword = (reqData, success, fail) =>{
    let s_code
    fetch(
        `${BaseUrl}${change_password_url}`,
        {
            method : 'POST',
            body : JSON.stringify(reqData),
            headers : {
                'Content-Type' : 'application/json'
            }
        }
    )
    .then((res) => {
        s_code = res.status
        return res.json()
    })
    .then((result) => {
        if (s_code == 200){
            success && success({...result})
        }
        else{
            fail && fail(result.message)
        }
    })
    .catch((err) => {
        fail && fail('Something went wrong')
    });
}


