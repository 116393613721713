import React, { useEffect, useState } from "react";
import "./EditProfile.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Button, useMediaQuery } from "@mui/material";
import PictureUploadProfile from "../../Components/PictureUploadProfile/PictureUploadProfile";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import DatePicker from "react-datepicker";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import countriesData from "country-region-data/data.json";
import { connect } from "react-redux";
import PhoneInput, {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { toast } from "react-toastify";
import { createProfileHttpRequest } from "../../static/http-content";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function EditProfile(props) {
  const intl = useIntl();
  const user = JSON.parse(localStorage.getItem("user"));
  const [userData, setUserData] = useState({
    first_name: user?.profile?.first_name,
    last_name: user?.profile?.last_name,
    phone: user?.profile?.phone,
    whatsapp_no: user?.profile?.whatsapp_no,
    dob: user?.profile?.dob ? user?.profile?.dob : null,
    country: user?.profile?.country,
    state: user?.profile?.state,
    road_no: user?.profile?.road_no,
    house_no: user?.profile?.house_no,
    biography: user?.profile?.biography,
    isFirstNameError: false,
    isLastNameError: false,
    isPhoneError: false,
    isWhatsappNoError: false,
    isRoadNoInvalid: false,
    isHouseNoInvalid: false,
    isStateInvalid: false,
  });

  const [dealer, setDealer] = useState({
    dealership_name: user?.dealer_info?.dealership_name,
    address: user?.dealer_info?.address,
    company_registration_number: user?.dealer_info?.company_registration_number,
    email: user?.dealer_info?.email,
    telephone: user?.dealer_info?.telephone,
    company_web_link: user?.dealer_info?.company_web_link,
    isDealershipNameInvalid: false,
    isAddressInvalid: false,
    isCompanyRegistrationNumberInvalid: false,
    isCompanyWebLinkInvalid: false,
  });

  const [password, setPassword] = useState({
    current_password: null,
    new_password: null,
    confirm_new_password: null,
  });
  const BaseUrl = "http://api-dev.autoby24.ch";
  const userType = localStorage.getItem("user_type");
  const [email, setEmail] = useState(user?.email);
  // const url = user?.profile?.profile_pic;
  // const hasHttps = "https://";

  const [profile_pic, setProfile_pic] = useState({
    file: null,
    file_url: BaseUrl + user?.profile?.profile_pic,
  });
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [profile_created, setProfile_created] = useState(false);
  const [refreshTokenApplied, setRefreshTokenApplied] = useState(false);

  // useEffect(() => {
  //   userDetailHttpRequest
  //     .get("/", {
  //       headers: { Authorization: `jwt ${localStorage.getItem("registertoken")}` },
  //     })
  //     .then((res) => {
  //       setUserData({ ...res?.data?.data.profile_info });
  //       setEmail(res?.data?.data.user_info.email);
  //       setProfile_pic({
  //         ...profile_pic,
  //         file_url: res?.data?.data.profile_pic_url,
  //         file: null,
  //       });
  //       setProfile_created(res?.data?.data.user_info.is_profile);
  //     })
  //     .catch((err) => {
  //       console.log(err.response);
  //       if (err.response?.data.detail === "access_token expired") {
  //         localStorage.setItem("refresh_token", localStorage.getItem("refresh_token"));
  //         setRefreshTokenApplied(true);
  //       }
  //     });
  // }, [refreshTokenApplied]);

  const updateProfilePicture = (image) => {
    let formData = new FormData();
    formData.append("profile_pic", image);
  };

  const submit = () => {
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let formData = new FormData();
    if (userType == "dealer") {
      formData.append("profile", JSON.stringify(userData));
      if (profile_pic.file) {
        formData.append("profile_pic", profile_pic.file);
      }
      formData.append("dealer_info", JSON.stringify(dealer));
    } else {
      formData.append("profile", JSON.stringify(userData));
      if (profile_pic.file) {
        formData.append("profile_pic", profile_pic.file);
      }
    }

    createProfileHttpRequest
      .post("/", formData, {
        headers: {
          Authorization: `jwt ${localStorage.getItem("refresh_token")}`,
        },
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res?.data?.data));
        toast.update(toast_id, {
          render: intl.formatMessage({ id: "Profile-Updated" }),
          type: "success",
          isLoading: false,
          closeOnClick: true,
          autoClose: 5000,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err.response));
  };
  return (
    <div id="EditProfile">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan">
          <FormattedMessage id="edit-profile" />
        </h3>
        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth">
            <div className="content_wrapper">
              <FormBoxHead
                profile_pic={profile_pic}
                setProfile_pic={setProfile_pic}
                updateProfilePicture={updateProfilePicture}
                profilePicLoading={profilePicLoading}
                userData={userData}
                setUserData={setUserData}
                setDealer={setDealer}
              />
              <FormBox1
                userData={userData}
                setUserData={setUserData}
                email={email}
                setEmail={setEmail}
              />
              {userType === "dealer" && (
                <FormBox2 dealer={dealer} setDealer={setDealer} />
              )}
              {userType === "dealer" ? (
                <FormSubmitDealer
                  userData={userData}
                  dealer={dealer}
                  submit={submit}
                />
              ) : (
                <FormSubmit userData={userData} submit={submit} />
              )}
            </div>
          </form>
        </Box>
      </Box>
    </div>
  );
}

const mapStateToProps = (state) => ({
  info: state.rootReducer.LoginReducer,
});

const mapDispatchToProps = (dispatch) => {
  return;
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);

const FormBoxHead = (props) => {
  const handleUploader = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      props.setProfile_pic({
        ...props.profile_pic,
        file: event.target.files[0],
        file_url: URL.createObjectURL(img),
      });

      props.updateProfilePicture(event.target.files[0]);
    }
  };

  return (
    <Box className="FormBoxHead">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 2, mt: 3 }}>
              <div className="form_group">
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader}
                />

                <PictureUploadProfile
                  profilePicLoading={props.profilePicLoading}
                  profile_pic={props.profile_pic.file_url}
                />
              </div>
            </Box>

            {/* <Stack className="edit_profile_tab">
              <TabBox
                userData={props.userData}
                setUserData={props.setUserData}
              ></TabBox>
            </Stack> */}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

// const TabBox = ({ userData, setUserData }) => {
//   let userType = localStorage.getItem("user_type");
//   const intl = useIntl();
//   useEffect(() => {
//     console.log(userData);
//   }, []);

//   // const handleChange = (event, newValue) => {
//   //   setValue(newValue);
//   // };
//   return (
//     <Box align="center" display="flex" justifyContent="center">
//       {(userType === "private") &&
//         <Tabs
//           value={0}
//           centered
//           className="overlap_tabs"
//         >
//           <Tab
//             disabled
//             sx={{ flex: 1, textTransform: "none" }}
//             label={intl.formatMessage({ id: "Private" })}
//           />
//           <Tab
//             disabled
//             sx={{ flex: 1, textTransform: "none" }}
//             label={intl.formatMessage({ id: "Dealer" })}
//           />
//         </Tabs>
//       }
//       {(userType === "dealer") &&
//         <Tabs
//           value={1}
//           centered
//           className="overlap_tabs"
//         >
//           <Tab
//             disabled
//             sx={{ flex: 1, textTransform: "none" }}
//             label={intl.formatMessage({ id: "Private" })}
//           />
//           <Tab
//             disabled
//             sx={{ flex: 1, textTransform: "none" }}
//             label={intl.formatMessage({ id: "Dealer" })}
//           />
//         </Tabs>
//       }
//     </Box>
//   );
// };

const onlyCharactersAllow = (value) => {
  return /^[a-zA-Z\s]*$/.test(value || "");
};

const onlyAlphaNumericAllow = (value) => {
  return /^[a-zA-Z0-9\s]*$/.test(value || "");
};

const onlyUrlAllow = (value) => {
  return /\bhttps?:\/\/(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?\b/.test(
    value || ""
  );
};

const FormBox1 = (props) => {
  const intl = useIntl();
  const matches = useMediaQuery("(min-width:640px)");

  const validateState = (value) => {
    debugger;
    let countryName = props?.userData?.country;
    if (countryName && typeof countryName === "string") {
      const matchResult = countryName.match(/^([^\(]+)\s/);
      if (matchResult) {
        const countryData = countriesData.find(
          (country) => country.countryName === matchResult[1].trim()
        );
        if (countryData) {
          const isValid = countryData.regions.some(
            (region) => region.name.toLowerCase() === value.toLowerCase()
          );
          return isValid;
        }
      }
    }
    return false;
  };

  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">
          <FormattedMessage id="Profile-Details" />
        </h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="First-Name" />:{" "}
                  <span className="star_symbol">*</span>
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "First-Name" })}
                  value={props?.userData?.first_name}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      first_name: e.target.value,
                      isFirstNameError: !onlyCharactersAllow(e.target.value),
                    });
                  }}
                />
                {props?.userData?.isFirstNameError && (
                  <span className="form_error">
                    <FormattedMessage id="FirstNameError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Last-Name" />:{" "}
                  <span className="star_symbol">*</span>
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Last-Name" })}
                  value={props?.userData?.last_name}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      last_name: e.target.value,
                      isLastNameError: !onlyCharactersAllow(e.target.value),
                    });
                  }}
                />
                {props?.userData?.isLastNameError && (
                  <span className="form_error">
                    <FormattedMessage id="LastNameError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="email" />:
                </label>
                <input
                  type="email"
                  className="form_control"
                  disabled
                  placeholder={intl.formatMessage({ id: "email" })}
                  value={props?.email}
                  onChange={(e) => {
                    props.setEmail(e.target.value);
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Phone" />:
                </label>
                <div className="form_country_number_control">
                  <PhoneInput
                    international
                    defaultCountry="BD"
                    value={props?.userData?.phone}
                    onChange={(e) => {
                      const isValid = isValidPhoneNumber(e || "");
                      const isPossible = isPossiblePhoneNumber(e || "");
                      props.setUserData({
                        ...props.userData,
                        phone: e,
                        isPhoneError: !(isValid && isPossible),
                      });
                    }}
                    limitMaxLength
                  />
                </div>
                {props?.userData?.isPhoneError &&
                  props?.userData?.phone?.length > 1 && (
                    <span className="form_error">
                      <FormattedMessage id="PhoneError" />
                    </span>
                  )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Biography" />:{" "}
                  <span className="star_symbol">*</span>
                </label>
                <textarea
                  rows="6"
                  placeholder={intl.formatMessage({ id: "Describe" })}
                  className="form_control"
                  value={props?.userData?.biography}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      biography: e.target.value,
                    });
                  }}
                ></textarea>
                {props?.userData?.biography?.length > 0 &&
                  props?.userData?.biography?.length < 20 && (
                    <span className="form_error">
                      <FormattedMessage id="DescribeError" />
                    </span>
                  )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Date-of-Birth" />:
                </label>
                <DatePicker
                  className="datePicker form_control"
                  selected={new Date(props?.userData?.dob)}
                  maxDate={new Date()}
                  onChange={(date) =>
                    props.setUserData({
                      ...props.userData,
                      dob: date,
                    })
                  }
                />
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="WhatsApp-Number" />:
                </label>
                <div className="form_country_number_control">
                  <PhoneInput
                    international
                    defaultCountry="BD"
                    value={props?.userData?.whatsapp_no}
                    onChange={(e) => {
                      const isValid = isValidPhoneNumber(e || "");
                      const isPossible = isPossiblePhoneNumber(e || "");
                      props.setUserData({
                        ...props.userData,
                        whatsapp_no: e,
                        isWhatsappNoError: !(isValid && isPossible),
                      });
                    }}
                    limitMaxLength
                  />
                </div>
                {props?.userData?.isWhatsappNoError &&
                  props?.userData?.whatsapp_no?.length > 1 && (
                    <span className="form_error">
                      <FormattedMessage id="WhatsAppNumberError" />
                    </span>
                  )}
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Road-Name" />:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Road-Name" })}
                  value={props?.userData?.road_no}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      road_no: e.target.value,
                      isRoadNoInvalid: !onlyCharactersAllow(e.target.value),
                    });
                  }}
                />
                {props?.userData?.isRoadNoInvalid && (
                  <span className="form_error">
                    <FormattedMessage id="RoadNameError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="House-No" />:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "House-No" })}
                  maxLength={6}
                  value={props?.userData?.house_no}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      house_no: e.target.value,
                      isHouseNoInvalid: !onlyAlphaNumericAllow(e.target.value),
                    });
                  }}
                />
                {props?.userData?.isHouseNoInvalid && (
                  <span className="form_error">
                    <FormattedMessage id="HouseNoError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Country" />:
                </label>

                <Box className="form_group form_country_group_full_width">
                  <CountryDropdown
                    style={{ padding: "0px" }}
                    className="form_control"
                    preferredCountries={["bd", "in"]}
                    value={props?.userData?.country}
                    handleChange={(e) => {
                      props.setUserData({
                        ...props.userData,
                        country: e.target.value,
                      });
                    }}
                  ></CountryDropdown>
                </Box>
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="State" />:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "State" })}
                  value={props?.userData?.state}
                  onChange={(e) => {
                    props.setUserData({
                      ...props.userData,
                      state: e.target.value,
                      isStateInvalid: !validateState(e.target.value),
                    });
                  }}
                />
                {props?.userData?.state?.length > 1 &&
                  props?.userData?.isStateInvalid && (
                    <span className="form_error">
                      <FormattedMessage id="StateError" />
                    </span>
                  )}
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox2 = (props) => {
  const intl = useIntl();
  const matches = useMediaQuery("(min-width:640px)");
  const validateCompanyRegistrationNumber = (value) => {
    return /^[A-Z]{2}-\d{2}\.\d{3}\.\d{2}\.\d{4}$/.test(value || "");
  };
  return (
    <Box>
      <Box textAlign="center">
        <h2 className="title_line">
          <FormattedMessage id="Dealer-Details" />
        </h2>
      </Box>{" "}
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Dealership-Name" />:{" "}
                  <span className="star_symbol">*</span>
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Dealership-Name" })}
                  value={props?.dealer?.dealership_name}
                  onChange={(e) => {
                    props.setDealer({
                      ...props.dealer,
                      dealership_name: e.target.value,
                      isDealershipNameInvalid: !onlyAlphaNumericAllow(
                        e.target.value
                      ),
                    });
                  }}
                />
                {props?.dealer?.isDealershipNameInvalid && (
                  <span className="form_error">
                    <FormattedMessage id="DealershipNameError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Address" />:
                  <span className="star_symbol">*</span>
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Address" })}
                  value={props?.dealer?.address}
                  onChange={(e) => {
                    props.setDealer({
                      ...props.dealer,
                      address: e.target.value,
                      isAddressInvalid: !onlyAlphaNumericAllow(e.target.value),
                    });
                  }}
                />
                {props?.dealer?.isAddressInvalid && (
                  <span className="form_error">
                    <FormattedMessage id="AddressError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Company-Registration-Number" />:
                  <span className="star_symbol">*</span>
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({
                    id: "Company-Registration-Number",
                  })}
                  maxLength={17}
                  value={props?.dealer?.company_registration_number}
                  onChange={(e) => {
                    props.setDealer({
                      ...props.dealer,
                      company_registration_number: e.target.value,
                      isCompanyRegistrationNumberInvalid:
                        !validateCompanyRegistrationNumber(e.target.value),
                    });
                  }}
                />
                {props?.dealer?.isCompanyRegistrationNumberInvalid && (
                  <span className="form_error">
                    <FormattedMessage id="CompanyRegistrationNumberError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>

        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: matches ? 2 : 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                  <FormattedMessage id="Company-Web-Link" />:
                  <span className="star_symbol">*</span>
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder={intl.formatMessage({ id: "Company-Web-Link" })}
                  value={props?.dealer?.company_web_link}
                  onChange={(e) => {
                    props.setDealer({
                      ...props.dealer,
                      company_web_link: e.target.value,
                      isCompanyWebLinkInvalid: !onlyUrlAllow(e.target.value),
                    });
                  }}
                />
                {props?.dealer?.isCompanyWebLinkInvalid && (
                  <span className="form_error">
                    <FormattedMessage id="CompanyWebLinkError" />
                  </span>
                )}
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSubmit = (props) => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button
        sx={{ px: 6 }}
        className="btn_cyan_style"
        onClick={props.submit}
        disabled={
          !props.userData.first_name ||
          props.userData.isFirstNameError ||
          !props.userData.last_name ||
          props.userData.isLastNameError ||
          props.userData.biography?.length < 20 ||
          props?.userData?.isPhoneError ||
          props?.userData?.isWhatsappNoError ||
          props?.userData?.isRoadNoInvalid ||
          props?.userData?.isHouseNoInvalid ||
          props?.userData?.isStateInvalid
            ? true
            : false
        }
      >
        <FormattedMessage id="UPDATE-PROFILE" />
      </Button>
    </Box>
  );
};

const FormSubmitDealer = (props) => {
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button
        sx={{ px: 6 }}
        className="btn_cyan_style"
        onClick={props.submit}
        disabled={
          !props.userData.first_name ||
          props.userData.isFirstNameError ||
          !props.userData.last_name ||
          props.userData.isLastNameError ||
          props.userData.biography?.length < 20 ||
          props?.userData?.isPhoneError ||
          props?.userData?.isWhatsappNoError ||
          props?.userData?.isRoadNoInvalid ||
          props?.userData?.isHouseNoInvalid ||
          props?.userData?.isStateInvalid ||
          !props.dealer.dealership_name ||
          props.dealer.isDealershipNameInvalid ||
          !props.dealer.company_registration_number ||
          props.dealer.isCompanyRegistrationNumberInvalid ||
          !props.dealer.address ||
          props.dealer.isAddressInvalid ||
          !props.dealer.company_web_link ||
          props.dealer.isCompanyWebLinkInvalid
            ? true
            : false
        }
      >
        <FormattedMessage id="UPDATE-PROFILE" />
      </Button>
    </Box>
  );
};
