import React, { useEffect, useState } from "react";
import "./SellBike2.scss";
import "../../static/css/style_helper/style_helper.scss";
import "../../static/css/forms_style/forms_style.scss";
import "../../static/css/forms_style/forms_style.css";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import PictureUploader from "../../Components/PictureUploader/PictureUploader";
import { Button, useMediaQuery } from "@mui/material";
import { DropzoneArea, DropzoneAreaBase } from "material-ui-dropzone";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { getListOfCountryHttpRequest, getListOfBrandModelHttpRequest, getListOfBrandHttpRequest, getPackagesListHttpRequest, createVehicleHttpRequest, updateVehicleHttpRequest, deleteVehicleImageHttpRequest } from "../../static/http-content";
import moment from "moment";
import { toast } from "react-toastify";
import { useIntl } from 'react-intl';
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage } from 'react-intl';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  background: "transparent",
  boxShadow: 0,
}));

function SellCar2() {
  const intl = useIntl();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const carId = queryParams.get("carId");
  const user = JSON.parse(localStorage.getItem("user"));
  const [filtered, setfiltered] = useState(null);
  const [vehicleData, setVehicleData] = useState({
    first_name: user?.profile?.first_name,
    last_name: user?.profile?.last_name,
    features: null,
    weight: null,
    characteristics: null,
    environment_energy: null,
    dealer_services: null,
    engine_and_drive: null,
    warrenty: null,
    brand: null,
    services: null,
    model_no: null,
    vehicle_no: null,
    model_year: 0,
    registration_date: new Date(),
    title: null,
    transmission: null,
    power: null,
    power_value: null,
    drive: null,
    miles: 0,
    furnishing: null,
    consumption: 0,
    emission: 0,
    no_of_seats: 0,
    safety_rating: 0,
    old_price: 0,
    new_price: 0,
    fuel: null,
    vehicle_condition: null,
    color: null,
    doors: null,
    shipping_country: null,
    postcode: null,
    type: null,
    package: id,
    business: null,
    address: null,
    npa: null,
    locality: null,
    contact_number: null,
    country: null,
    supplementary_information: null,
    contact_code: null,
    contact_title: null
  });
  const [vehicle_rc, setVehicle_rc] = useState({
    registration_image: null,
    registration_image_url: null,
  });
  const [linked_images, setLinkedImages] = useState(null);
  const [previewImage, setPreviewImage] = useState([]);
  const [brand, setBrand] = useState([]);
  const navigate = useNavigate();
  const [models, setModels] = useState([]);

  useEffect(() => {
    getListOfBrandHttpRequest
      .get('/', {
        headers: {
          Authorization: `jwt ${localStorage.getItem("token")}`,
        }
      })
      .then((res) => {
        setBrand(res?.data?.data);
      })
      .catch((err) => console.log(err.response));
    if (carId) {
      getPackagesListHttpRequest
        .get(`/${carId}/`, {
          headers: {
            Authorization: `jwt ${localStorage.getItem("token")}`,
          }
        })
        .then((res) => {
          getListOfBrandModelHttpRequest
            .get(`/?brand__name=${res?.data?.data?.brand}`, {
              headers: {
                Authorization: `jwt ${localStorage.getItem("token")}`,
              }
            })
            .then((res) => {
              setModels(res?.data?.data);
            })
            .catch((err) => console.log(err.response));
          setfiltered(res?.data?.data);
          let temp = res?.data?.data?.contact_number.substr(0, 3);
          let remove = res?.data?.data?.contact_number.slice(3);
          setVehicleData({
            first_name: res?.data?.data?.first_name ? res?.data?.data?.first_name : user?.profile?.first_name,
            last_name: res?.data?.data?.last_name ? res?.data?.data?.last_name : user?.profile?.last_name,
            features: res?.data?.data?.features[0],
            weight: res?.data?.data?.weight[0],
            characteristics: res?.data?.data?.characteristics[0],
            environment_energy: res?.data?.data?.environment_energy[0],
            dealer_services: res?.data?.data?.dealer_services[0],
            engine_and_drive: res?.data?.data?.engine_and_drive[0],
            warrenty: res?.data?.data?.warrenty[0],
            brand: res?.data?.data?.brand,
            services: res?.data?.data?.services,
            model_no: res?.data?.data?.model_no,
            vehicle_no: res?.data?.data?.vehicle_no,
            model_year: res?.data?.data?.model_year ? res?.data?.data?.model_year : 0,
            registration_date: res?.data?.data?.registration_date ? res?.data?.data?.registration_date : new Date(),
            title: res?.data?.data?.title,
            transmission: res?.data?.data?.transmission,
            power: res?.data?.data?.power,
            power_value: res?.data?.data?.power_value,
            drive: res?.data?.data?.drive,
            miles: res?.data?.data?.miles ? res?.data?.data?.miles : 0,
            furnishing: res?.data?.data?.furnishing,
            consumption: res?.data?.data?.consumption ? res?.data?.data?.consumption : 0,
            emission: res?.data?.data?.emission ? res?.data?.data?.emission : 0,
            no_of_seats: res?.data?.data?.no_of_seats ? res?.data?.data?.no_of_seats : 0,
            safety_rating: res?.data?.data?.safety_rating ? res?.data?.data?.safety_rating : 0,
            old_price: res?.data?.data?.old_price ? res?.data?.data?.old_price : 0,
            new_price: res?.data?.data?.new_price ? res?.data?.data?.new_price : 0,
            fuel: res?.data?.data?.fuel,
            vehicle_condition: res?.data?.data?.vehicle_condition,
            color: res?.data?.data?.color,
            doors: res?.data?.data?.doors,
            shipping_country: res?.data?.data?.shipping_country,
            postcode: res?.data?.data?.postcode,
            type: res?.data?.data?.type,
            package: id,
            business: res?.data?.data?.business,
            address: res?.data?.data?.address,
            npa: res?.data?.data?.npa,
            locality: res?.data?.data?.locality,
            contact_number: remove,
            country: res?.data?.data?.country,
            supplementary_information: res?.data?.data?.supplementary_information,
            contact_code: temp,
            contact_title: res?.data?.data?.contact_title
          });
          setVehicle_rc({
            registration_image: null,
            registration_image_url: res?.data?.data?.insurance_image,
          });
          setPreviewImage(res?.data?.data?.images)
        })
        .catch((err) => console.log(err.response));
    }
  }, []);

  const submit = () => {
    let toast_id = toast.loading(intl.formatMessage({ id: "Please-wait" }));
    let formData = new FormData();
    formData.append("features", vehicleData?.features);
    formData.append("weight", vehicleData?.weight);
    formData.append("characteristics", vehicleData?.characteristics);
    formData.append("environment_energy", vehicleData?.environment_energy);
    formData.append("dealer_services", vehicleData?.dealer_services);
    formData.append("engine_and_drive", vehicleData?.engine_and_drive);
    formData.append("warrenty", vehicleData?.warrenty);
    formData.append("brand", vehicleData?.brand);
    formData.append("services", vehicleData?.services);
    formData.append("model_no", vehicleData?.model_no);
    formData.append("vehicle_no", vehicleData?.vehicle_no);
    formData.append("model_year", vehicleData?.model_year);
    formData.append("title", vehicleData?.title);
    formData.append("registration_date", moment(vehicleData?.registration_date).format("YYYY-MM-DD"));
    formData.append("transmission", vehicleData?.transmission);
    formData.append("power", vehicleData?.power);
    formData.append("power_value", vehicleData?.power_value);
    formData.append("drive", vehicleData?.drive);
    formData.append("miles", vehicleData?.miles);
    formData.append("furnishing", vehicleData?.furnishing);
    formData.append("consumption", vehicleData?.consumption);
    formData.append("emission", vehicleData?.emission);
    formData.append("no_of_seats", +vehicleData?.no_of_seats);
    formData.append("safety_rating", vehicleData?.safety_rating);
    formData.append("package", vehicleData?.package);
    formData.append("fuel", vehicleData?.fuel);
    formData.append("color", vehicleData?.color);
    formData.append("vehicle_condition", vehicleData?.vehicle_condition);
    formData.append("shipping_country", vehicleData?.shipping_country);
    formData.append("doors", parseInt(vehicleData?.doors));
    formData.append("type", vehicleData?.type);
    formData.append("postcode", vehicleData?.postcode);
    formData.append("old_price", vehicleData?.old_price);
    formData.append("new_price", vehicleData?.new_price);
    formData.append("first_name", vehicleData?.first_name);
    formData.append("last_name", vehicleData?.last_name);
    formData.append("business", vehicleData?.business);
    formData.append("country", vehicleData?.country);
    formData.append("address", vehicleData?.address);
    formData.append("npa", vehicleData?.npa);
    formData.append("locality", vehicleData?.locality);
    formData.append("contact_number", vehicleData?.contact_code + vehicleData?.contact_number);
    formData.append("supplementary_information", vehicleData?.supplementary_information);

    if (vehicle_rc?.registration_image) {
      formData.append("insurance_image", vehicle_rc?.registration_image);
    }
    if (linked_images) {
      linked_images.forEach(element => {
        formData.append("linked_images", element);
      });
    }
    if (carId) {
      updateVehicleHttpRequest
        .patch(`/${carId}/`, formData, {
          headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          toast.update(
            toast_id,
            {
              render: "Vehicle Updated Successfully",
              type: 'success',
              isLoading: false,
              closeOnClick: true,
              autoClose: 5000,
              pauseOnHover: true,
            }
          )
          navigate(`/sell_car?id=${id}`);
        })
        .catch((err) => {
          toast.update(
            toast_id,
            {
              render: err?.response?.data?.errors?.image[0],
              type: 'error',
              isLoading: false,
              closeOnClick: true,
              autoClose: 5000,
              pauseOnHover: true,
            }
          );
          console.log(err.response)
        });
    } else {
      createVehicleHttpRequest
        .post("/", formData, {
          headers: { Authorization: `jwt ${localStorage.getItem("token")}` },
        })
        .then((res) => {
          toast.update(
            toast_id,
            {
              render: "Vehicle Created Successfully",
              type: 'success',
              isLoading: false,
              closeOnClick: true,
              autoClose: 5000,
              pauseOnHover: true,
            }
          );
          navigate(`/sell_car?id=${id}`);
        })
        .catch((err) => {
          toast.update(
            toast_id,
            {
              render: err?.response?.data?.errors?.image[0],
              type: 'error',
              isLoading: false,
              closeOnClick: true,
              autoClose: 5000,
              pauseOnHover: true,
            }
          );
          console.log(err.response);
        });
    }

  };

  const handleChange = (e) => {
    setVehicleData({
      ...vehicleData,
      brand: e.target.value,
    });
    getListOfBrandModelHttpRequest
      .get(`/?brand__name=${e.target.value}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem("token")}`,
        }
      })
      .then((res) => {
        setModels(res?.data?.data);
      })
      .catch((err) => console.log(err.response));
  };

  return (
    <div id="SellCar11">
      <Box sx={{ pt: 6 }}>
        <h3 className="section_title_cyan max_size_cyan"><FormattedMessage id="Sell-Vehicle"/></h3>
        <Box id="section1" component="section" sx={{ mt: 6 }}>
          <form action="" className="form_auth1">
            <div className="content_wrapper">
              <div className="cus-form">
                <Accordion expanded={true} square='false' sx={{
                  backgroundColor: 'rgba(71, 72, 74, 0.9)', color: 'white', borderRadius: '20px'
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography><FormattedMessage id="Vehicle-Information"/></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormBox1
                        vehicleData={vehicleData}
                        setVehicleData={setVehicleData}
                        linked_images={linked_images}
                        setLinkedImages={setLinkedImages}
                        previewImage={previewImage}
                        setPreviewImage={setPreviewImage}
                        brand={brand}
                        models={models}
                        handleChange={handleChange}
                      ></FormBox1>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </Box>
        <Box id="section1" component="section" sx={{ mt: 2 }}>
          <form action="" className="form_auth1">
            <div className="content_wrapper">
              <div className="cus-form">
                <Accordion expanded={true} square='false' sx={{
                  backgroundColor: 'rgba(71, 72, 74, 0.9)', color: 'white', borderRadius: '20px'
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography><FormattedMessage id="Essential-Features"/></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormBox2
                        vehicleData={vehicleData}
                        setVehicleData={setVehicleData}
                        vehicle_rc={vehicle_rc}
                        setVehicle_rc={setVehicle_rc}
                      ></FormBox2>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </Box>
        <Box id="section1" component="section" sx={{ mt: 2 }}>
          <form action="" className="form_auth1">
            <div className="content_wrapper">
              <div className="cus-form">
                <Accordion square='false' sx={{
                  backgroundColor: 'rgba(71, 72, 74, 0.9)', color: 'white', borderRadius: '20px'
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography><FormattedMessage id="Standard-Features"/></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {/* <FormBox3
                        vehicleData={vehicleData}
                        setVehicleData={setVehicleData}
                      ></FormBox3> */}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </Box>
        <Box id="section1" component="section" sx={{ mt: 0 }}>
          <form action="" className="form_auth1">
            <div className="content_wrapper">
              <div className="cus-form">
                <Accordion square='false' sx={{
                  backgroundColor: 'rgba(71, 72, 74, 0.9)', color: 'white', borderRadius: '20px'
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography><FormattedMessage id="Optional-Accessories"/></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {/* <FormBox4
                        vehicleData={vehicleData}
                        setVehicleData={setVehicleData}
                      ></FormBox4> */}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </Box>
        <Box id="section1" component="section" sx={{ mt: 0 }}>
          <form action="" className="form_auth1">
            <div className="content_wrapper">
              <div className="cus-form">
                <Accordion square='false' sx={{
                  backgroundColor: 'rgba(71, 72, 74, 0.9)', color: 'white', borderRadius: '20px'
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography><FormattedMessage id="Details"/></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {/* <FormBox5
                        vehicleData={vehicleData}
                        setVehicleData={setVehicleData}
                      ></FormBox5> */}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </Box>
        <Box id="section1" component="section" sx={{ mt: 0 }}>
          <form action="" className="form_auth1">
            <div className="content_wrapper">
              <div className="cus-form">
                <Accordion square='false' sx={{
                  backgroundColor: 'rgba(71, 72, 74, 0.9)', color: 'white', borderRadius: '20px'
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography><FormattedMessage id="Extras"/></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {/* <FormBox6
                        vehicleData={vehicleData}
                        setVehicleData={setVehicleData}
                      ></FormBox6> */}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </Box>
        <Box id="section1" component="section" sx={{ mt: 0 }}>
          <form action="" className="form_auth1">
            <div className="content_wrapper">
              <div className="cus-form">
                <Accordion expanded={true} square='false' sx={{
                  backgroundColor: 'rgba(71, 72, 74, 0.9)', color: 'white', borderRadius: '20px'
                }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography><FormattedMessage id="Contact-Information"/></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormBox7
                        vehicleData={vehicleData}
                        setVehicleData={setVehicleData}
                      ></FormBox7>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </form>
        </Box>
        <FormSubmit submit={submit} />
      </Box>
    </div>
  );
}

export default SellCar2;

const FormBox1 = (props) => {
  const matches = useMediaQuery("(min-width:640px)");
  const queryParams = new URLSearchParams(window.location.search);
  const carId = queryParams.get("carId");
  const handleUploader = (files) => {
    if (files.length > 0) {
      props.setLinkedImages(files);
    }
  };

  const removeImg = (ids) => {
    deleteVehicleImageHttpRequest
      .delete(`/${ids}`)
      .then((res) => {
        if (carId) {
          getPackagesListHttpRequest
            .get(`/${carId}/`, {
              headers: {
                Authorization: `jwt ${localStorage.getItem("token")}`,
              }
            })
            .then((res) => {
              props.setPreviewImage(res?.data?.data?.images)
            })
            .catch((err) => console.log(err.response));
        }
      })
      .catch((err) => console.log(err.response));
  };
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={3}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Vehicle-No"/>:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Vehicle No..."
                  value={props?.vehicleData?.vehicle_no}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      vehicle_no: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Year-of-Registration"/>:
                </label>
                <div className="form_mui_group">
                  <input
                    type="number"
                    className="form_control"
                    placeholder="Model Year..."
                    value={props?.vehicleData?.model_year}
                    onChange={(e) => {
                      props.setVehicleData({
                        ...props.vehicleData,
                        model_year: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Month-of-Registration"/>:
                </label>
                <DatePicker
                  className="datePicker form_control"
                  selected={new Date(props?.vehicleData?.registration_date)}
                  onChange={(date) =>
                    props.setVehicleData({
                      ...props.vehicleData,
                      registration_date: date,
                    })
                  }
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Price"/>:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Price..."
                  value={props?.vehicleData?.old_price}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      old_price: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: "white" }}> <FormattedMessage id="Body-Color"/>:</FormLabel>
                <RadioGroup
                  row
                  value={props?.vehicleData?.color}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      color: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel value="Maroon" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Maroon"/>} />
                  <FormControlLabel value="Yellow" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Yellow"/>} />
                  <FormControlLabel value="Black" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Black"/>}/>
                  <FormControlLabel value="White" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="White"/>} />
                  <FormControlLabel value="Green" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Green"/>} />
                  <FormControlLabel value="Gray" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Gray"/>} />
                  <FormControlLabel value="Turquoise" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Turquoise"/>} />
                  <FormControlLabel value="Blue" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Blue"/>} />
                  <FormControlLabel value="Red" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Red"/>} />
                  <FormControlLabel value="Brown" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Brown"/>} />
                  <FormControlLabel value="Silver" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Silver"/>} />
                  <FormControlLabel value="Violet" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Violet"/>} />
                  <FormControlLabel value="Beige" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Beige"/>} />
                  <FormControlLabel value="Gold" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Gold"/>} />
                  <FormControlLabel value="Orange" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Orange"/>} />
                  <FormControlLabel value="Pink" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Pink"/>} />
                  <FormControlLabel value="Bronze" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Bronze"/>} />
                  <FormControlLabel value="Other" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Other"/>} />
                  <FormControlLabel value="Light blue" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Light-blue"/>} />
                  <FormControlLabel value="Anthracite" control={<Radio sx={{ color: "white" }} />} label={<FormattedMessage id="Anthracite"/>}/>
                </RadioGroup>
              </FormControl>
            </Box>
            {/* <Box sx={{ mb: 2, mt: 2 }}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Interior Color:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="color" control={<Radio />} label="Color" />
                  <FormControlLabel value="color1" control={<Radio />} label="Color" />
                  <FormControlLabel value="color2" control={<Radio />} label="Color" />
                  <FormControlLabel value="color3" control={<Radio />} label="Color" />
                  <FormControlLabel value="color4" control={<Radio />} label="Color" />
                  <FormControlLabel value="color5" control={<Radio />} label="Color" />
                  <FormControlLabel value="color6" control={<Radio />} label="Color" />
                  <FormControlLabel value="color7" control={<Radio />} label="Color" />
                </RadioGroup>
              </FormControl>
            </Box> */}
          </Item>
        </Grid>
        <Grid item xs={12} md={5}>
          <Item sx={{ boxShadow: 0 }} className="Upload_picture_dropzone_wrapper">
            <Box sx={{ mb: 0, mt: 0 }}>
              <DropzoneArea
                acceptedFiles={["image/*"]}
                dropzoneText={<FormattedMessage id="Drag"/>}
                onChange={handleUploader}
                filesLimit={20}
                showAlerts={false}
              />
              {props.previewImage.map((img) =>
                <div class="img-wrap">
                  <Button
                    id="clear"
                    type="button"
                    sx={{ textTransform: "capitalize", p: 0 }}
                    color="error"
                    onClick={() => removeImg(img.id)}
                  >
                    <DeleteIcon />
                  </Button>
                  <img id="output" src={img.image} style={{
                    width: "80px",
                    height: "65px",
                    borderRadius: "20px",
                    objectFit: "cover",
                    margin: "5px"
                  }} />
                </div>
              )}
            </Box>
          </Item>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={3}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Brand1"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Brand..."
                  defaultValue="s"
                  onChange={props.handleChange}
                  value={props?.vehicleData?.brand}
                >
                  <option value="s" disabled>
                    Select Brand...
                  </option>
                  {props.brand.map((h, i) => <option key={i} value={h.name}>{h.name}</option>)}
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Model-No"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Model No..."
                  defaultValue="s"
                  value={props?.vehicleData?.model_no}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      model_no: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Model No...
                  </option>
                  {props.models.map((h, i) => <option key={i} value={h.name}>{h.name}</option>)}
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Title"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Title..."
                  value={props?.vehicleData?.title}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Transmission"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Transmission..."
                  defaultValue="s"
                  value={props?.vehicleData?.transmission}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      transmission: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Transmission...
                  </option>
                  <option value="Manual">
                    Manual
                  </option>
                  <option value="Automatic">
                    Automatic
                  </option>
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Features"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="features..."
                  value={props?.vehicleData?.features}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      features: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Weight"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Weight..."
                  value={props?.vehicleData?.weight}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      weight: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Characteristics"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Characteristics..."
                  value={props?.vehicleData?.characteristics}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      characteristics: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Environment-Energy"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Environment Energy..."
                  value={props?.vehicleData?.environment_energy}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      environment_energy: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Dealer-Services"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Dealer Services..."
                  value={props?.vehicleData?.dealer_services}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      dealer_services: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Engine-and-Drive"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Engine and Drive..."
                  value={props?.vehicleData?.engine_and_drive}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      engine_and_drive: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Warrenty"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Warrenty..."
                  value={props?.vehicleData?.warrenty}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      warrenty: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Services"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Services..."
                  value={props?.vehicleData?.services}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      services: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Power"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Power..."
                  value={props?.vehicleData?.power}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      power: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Power-Value"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Power Value..."
                  value={props?.vehicleData?.power_value}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      power_value: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Drive"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Drive..."
                  defaultValue="s"
                  value={props?.vehicleData?.drive}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      drive: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Drive...
                  </option>
                  <option value="Rear-wheel">
                    Rear-wheel
                  </option>
                  <option value="Front-wheel">
                    Front-wheel
                  </option>
                  <option value="All-wheel (4x4)">
                    All-wheel (4x4)
                  </option>
                </select>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Miles"/>:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Miles..."
                  value={props?.vehicleData?.miles}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      miles: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Furnishing"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Furnishing..."
                  value={props?.vehicleData?.furnishing}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      furnishing: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Consumption"/>:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Consumption..."
                  value={props?.vehicleData?.consumption}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      consumption: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Emission"/>:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Emission..."
                  value={props?.vehicleData?.emission}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      emission: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="No-of-Seats"/>:
                </label>
                <select
                  className="form_control"
                  defaultValue="s"
                  value={props?.vehicleData?.no_of_seats}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      no_of_seats: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select No of Seats
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                </select>
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
      {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={12}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel value="No Guarantee" control={<Radio />} label="No Guarantee" />
                  <FormControlLabel value="From 1st Delivery" control={<Radio />} label="From 1st Delivery" />
                  <FormControlLabel value="From 1st Purchase" control={<Radio />} label="From 1st Purchase" />
                  <FormControlLabel value="From" control={<Radio />} label="From" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Item>
        </Grid>
      </Grid> */}
    </Box>
  );
};

const FormBox2 = (props) => {
  console.log(props.vehicle_rc)
  const matches = useMediaQuery("(min-width:640px)");
  const [country, setCountry] = useState([]);
  useEffect(() => {
    getListOfCountryHttpRequest
      .get('/', {
        headers: {
          Authorization: `jwt ${localStorage.getItem("token")}`,
        }
      })
      .then((res) => {
        setCountry(res?.data?.data);
      })
      .catch((err) => console.log(err.response));
  }, []);

  const handleUploader = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      props.setVehicle_rc({
        ...props.vehicle_rc,
        registration_image: event.target.files[0],
        registration_image_url: URL.createObjectURL(img),
      });
    }
  };

  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Vehicle-RC"/>:
                </label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  className="form_control"
                  placeholder="Year of Registration of Vehicle..."
                  id="Vehicle_rc_input"
                  onChange={handleUploader}
                />
                <PictureUploader
                  registration_image={props.vehicle_rc.registration_image_url}
                ></PictureUploader>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Fuel-Type"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Fuel Type"
                  defaultValue="s"
                  value={props?.vehicleData?.fuel}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      fuel: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Fuel...
                  </option>
                  <option value="Gasoline">
                    Gasoline
                  </option>
                  <option value="Diesel">
                    Diesel
                  </option>
                  <option value="Hybrid">
                    Hybrid
                  </option>
                  <option value="Electric">
                    Electric
                  </option>
                  <option value="Other">
                    Other
                  </option>
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Safety-Rating"/>:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="Rating..."
                  value={props?.vehicleData?.safety_rating}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      safety_rating: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Doors"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Doors..."
                  defaultValue="s"
                  value={props?.vehicleData?.doors}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      doors: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Doors...
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Vehicle-Type"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Vehicle Type..."
                  defaultValue="s"
                  value={props?.vehicleData?.vehicle_condition}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      vehicle_condition: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Vehicle Type...
                  </option>
                  <option value="new">
                    New
                  </option>
                  <option value="used">
                    Used
                  </option>
                </select>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="PostCode/Town"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="PostCode/Town..."
                  value={props?.vehicleData?.postcode}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      postcode: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Body-Type"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Body Type..."
                  defaultValue="s"
                  value={props?.vehicleData?.type}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      type: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Body Type...
                  </option>
                  <option value="SUV">
                    SUV
                  </option>
                  <option value="Coupe">
                    Coupe
                  </option>
                  <option value="Van">
                    Van
                  </option>
                  <option value="Wagon">
                    Wagon
                  </option>
                  <option value="Sedan">
                    Sedan
                  </option>
                  <option value="Convertible">
                    Convertible
                  </option>
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Country"/>:
                </label>
                <select
                  className="form_control"
                  placeholder="Country..."
                  defaultValue="s"
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      shipping_country: e.target.value,
                    });
                  }}
                  value={props?.vehicleData?.shipping_country}
                >
                  <option value="s" disabled>
                    Select Country...
                  </option>
                  {country.map((h, i) => <option key={i} value={h.name}>{h.name}</option>)}
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="New-Price"/>:
                </label>
                <input
                  type="number"
                  className="form_control"
                  placeholder="New Price..."
                  value={props?.vehicleData?.new_price}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      new_price: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormBox7 = (props) => {
  const matches = useMediaQuery("(min-width:640px)");
  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Title"/>:
                </label>
                <select
                  className="form_control"
                  defaultValue="s"
                  value={props?.vehicleData?.contact_title}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      contact_title: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Title
                  </option>
                  <option value="Mr">
                    Mr
                  </option>
                  <option value="Mis">
                    Mis
                  </option>
                </select>
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Business"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Business"
                  value={props?.vehicleData?.business}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      business: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Country"/>:
                </label>
                <select
                  className="form_control"
                  defaultValue="s"
                  value={props?.vehicleData?.country}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      country: e.target.value,
                    });
                  }}
                >
                  <option value="s" disabled>
                    Select Country
                  </option>
                  <option value="India">
                    India
                  </option>
                  <option value="Switzerland">
                    Switzerland
                  </option>
                </select>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="First-Name"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="First Name"
                  value={props?.vehicleData?.first_name}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      first_name: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="NPA"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="NPA"
                  value={props?.vehicleData?.npa}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      npa: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Contact-Number"/>:
                </label>
                <select
                  className="form_control"
                  defaultValue="s"
                  value={props?.vehicleData?.contact_code}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      contact_code: e.target.value,
                    });
                  }}
                >
                  <option value="+41">
                    +41
                  </option>
                  <option value="+91">
                    +91
                  </option>
                </select>
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={4}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Last-Name"/>:{" "}
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Last Name..."
                  value={props?.vehicleData?.last_name}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      last_name: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 6, mt: 2 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Locality"/>:
                </label>
                <input
                  type="text"
                  className="form_control"
                  placeholder="Locality"
                  value={props?.vehicleData?.locality}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      locality: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <div className="form_group">
                <input
                  type="text"
                  className="form_control"
                  placeholder="Contact Number"
                  value={props?.vehicleData?.contact_number}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      contact_number: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Address"/>:
                </label>
                <textarea
                  type="text"
                  style={{ borderRadius: "5px" }}
                  className="form_control"
                  rows={3}
                  value={props?.vehicleData?.address}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      address: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} md={6}>
          <Item sx={{ boxShadow: 0 }}>
            <Box sx={{ mb: 0, mt: 0 }}>
              <div className="form_group">
                <label htmlFor="" className="form_label">
                <FormattedMessage id="Supplementary"/>:
                </label>
                <textarea
                  type="text"
                  style={{ borderRadius: "5px" }}
                  className="form_control"
                  rows={3}
                  value={props?.vehicleData?.supplementary_information}
                  onChange={(e) => {
                    props.setVehicleData({
                      ...props.vehicleData,
                      supplementary_information: e.target.value,
                    });
                  }}
                />
              </div>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

const FormSubmit = (props) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  return (
    <Box textAlign="center" sx={{ my: 3, mt: 5 }}>
      <Button type="button" sx={{ px: 6 }} className="btn_cyan_style" onClick={props.submit}>
      <FormattedMessage id="SUBMIT"/>
      </Button>
      {/* <Button type="button" sx={{ px: 6, mx: 2 }} className="btn_cyan_style">
        CANCEL
      </Button> */}
      <Button type="button" sx={{ px: 6, ml: 1 }} className="btn_cyan_style" component={Link}
        to={`/sell_car?id=${id}`}>
     <FormattedMessage id="CANCEL"/>
      </Button>
    </Box>
  );
};
